<template>
    <div>
        <UseTermsModal v-model="showServiceTerms"></UseTermsModal>
        <PrivacyPolicyTermsModal v-model="showPolicyTerms"></PrivacyPolicyTermsModal>
        <ConsentTerm
            ref="consertTermComponent"
            :cart="cart"
            :coin="getCoinSelectedObj"
            :campaignLandingPageConfig="campaignLandingPageConfig"
            v-model="showConsentTerm"
            :recurrencePeriodList="campaignDonationRecurrencePeriodList"
            :totalPrice="cartTotalPrice"
            :taxValue="cartTaxMethodPayment()"
            :parcelament="getParcelament"
            :entityName="companyCampaignName"
            :entityCNPJ="companyCampaignCNPJ"
            @accept="acceptConsentTerm"
        ></ConsentTerm>
        <Simulation
            :campaign="campaignLandingPageConfig.campaign"
            :landingPageOptions="landingPageOptions"
            @calculate="updateSimulationValue"
        />
        <div class="cart-father" v-if="cart">
            <!--box de projetos carrinho -->
            <v-layout content-cart>
                <v-layout box-project-cart column align-start>
                    <v-flex
                        bg-white
                        indique-valor
                        shrink
                        v-if="campaignLandingPageConfig.campaign.campaignTypeId != CampaignTypes.Ticket"
                    >
                        <p class="px-4 pt-4 pb-2 ma-0 f-size-16 color-theme-texto">
                            Indique nos campos de cada projeto o valor que deseja doar. O
                            valor mínimo desta campanha é
                            <span>R${{ formatterHelper.formatMoney(minimumPeriodDonationValue, 0) }}</span>
                        </p>
                        <p class="px-4">
                            <v-switch
                                v-if="enableCoin"
                                v-model="selectDollar"
                                :label="'Doar em dolar'"
                            ></v-switch>
                        </p>
                    </v-flex>
                    <v-flex bg-white indique-valor shrink v-else>
                        <p class="px-4">
                            <v-switch
                                v-if="enableCoin"
                                v-model="selectDollar"
                                :label="'Doar em dolar'"
                            ></v-switch>
                        </p>
                    </v-flex>
                    <v-flex scroll-box-project v-if="cart.cartItems.length > 0">
                        <template v-for="cartItem in cart.cartItems">
                            <v-flex replace-box-project pa-4 :key="cartItem.id">
                                <v-layout row break-name p-relative>
                                    <v-flex img-project-cart shrink mr-4>
                                        <FileImage
                                            :file="cartItem.project.projectLandingPageConfig.heroFile"
                                            :alt="''"
                                        />
                                    </v-flex>
                                    <v-flex shrink content-box w-100>
                                        <h5
                                            class="pr-4 mt-3 color-theme-destaque"
                                            v-if="cartItem.project.institution"
                                        >{{ cartItem.project.institution.entity.name }}</h5>
                                        <h3
                                            class="fw-400 color-theme-texto"
                                        >{{ cartItem.project.shortName }}</h3>
                                        <InputMoney
                                            v-if="cartItem.project.projectTypeId != ProjectTypes.Ticket"
                                            v-model="cartItem.itemValue"
                                            :precision="0"
                                            suffix=",⁰⁰"
                                            :prefix="getCoinSelectedObj.prefix"
                                            ref="toInput"
                                            :pattern="'\\d*'"
                                            invalidInputMessage="Preencha o valor da doação"
                                        />
                                        <div class="number-combo" v-else>
                                            <button
                                                v-if="!cartItem.donationProduct.openValue"
                                                v-on:disabled="minimumPurchase(cartItem.donationProduct)"
                                                @click="cartItem.donationProduct.currentQuantity--"
                                            >
                                                <i class="fal fa-minus"></i>
                                            </button>
                                            <div
                                                class="t-center"
                                                v-if="!cartItem.donationProduct.openValue"
                                            >
                                                <label class="label-qtde-cart">Qtde</label>
                                                <input
                                                    style="width: auto"
                                                    id="sizeWindow"
                                                    type="number"
                                                    min="0"
                                                    max="99999"
                                                    v-model="cartItem.donationProduct.currentQuantity"
                                                    @change="changeCurrentQuantity(cartItem)"
                                                />
                                            </div>
                                            <button
                                                v-if="!cartItem.donationProduct.openValue"
                                                :disabled="maximumPurchase(cartItem)"
                                                @click="cartItem.donationProduct.currentQuantity++"
                                            >
                                                <i class="fal fa-plus"></i>
                                            </button>
                                            <div
                                                v-if="!cartItem.donationProduct.openValue"
                                                class="value-cart-field"
                                                v-bind:class="{ 'value-cart-field-br': !selectDollar, 'value-cart-field-us': selectDollar }"
                                                style="width: auto; padding-left: 1rem"
                                            >
                                                <input
                                                    style="border: 0 none; height: 44px; outline: none; width: auto;"
                                                    id="sizeWindow"
                                                    type="number"
                                                    min="0"
                                                    max="9999999"
                                                    :value="cartItem.donationProduct.currentQuantity * cartItem.donationProduct.fixedValue"
                                                    @blur="changeCurrentValue(cartItem, $event)"
                                                />
                                            </div>
                                            <div
                                                v-else
                                                class="value-cart-field"
                                                v-bind:class="{ 'value-cart-field-br': !selectDollar, 'value-cart-field-us': selectDollar }"
                                                style="width: auto; padding-left: 1rem"
                                            >
                                                <input
                                                    style="border: 0 none; height: 44px; outline: none; width: auto;"
                                                    id="sizeWindow"
                                                    type="number"
                                                    min="0"
                                                    max="9999999"
                                                    v-model="cartItem.donationProduct.fixedValue"
                                                    @blur="changeCurrentValue(cartItem, $event)"
                                                />
                                            </div>
                                        </div>
                                        <p v-if="cartItem.donationProduct && cartItem.donationProduct.suggestionValue">
                                            Valor sugerido:
                                            {{ cartItem.donationProduct.suggestionValue }}
                                        </p>
                                    </v-flex>
                                    <a
                                        href="#"
                                        class="close-project-cart"
                                        @click="openRemoveCartItem(cartItem); $gtag('send', 'event', 'Remover', 'Carrinho Doação', 'Icone Lixeira')"
                                    >
                                        <Icon name="fal fa-trash" size="20" colorFont="#cfd2dd" />
                                    </a>
                                </v-layout>
                                <v-flex mt-4 xs12 v-if="cartItem.donationProduct">
                                    <v-layout sub-project>
                                        <v-flex shrink mr-3>
                                            <FileImage
                                                :file="cartItem.donationProduct.file"
                                                :alt="''"
                                            />
                                        </v-flex>
                                        <v-flex shrink content-box>
                                            <span
                                                class="color-theme-destaque"
                                                v-if="cartItem.project.projectTypeId != ProjectTypes.Ticket"
                                            >Você escolheu doar o valor acima para:</span>
                                            <span
                                                class="color-theme-destaque"
                                                v-else
                                            >{{ cartItem.donationProduct.finalDate ? formatterHelper.formatDate(cartItem.donationProduct.initialDate) + " - " + formatterHelper.formatDate(cartItem.donationProduct.finalDate) : formatterHelper.formatDate(cartItem.donationProduct.initialDate) }}</span>
                                            <h3
                                                class="my-1 fw-400 color-theme-texto"
                                            >{{ cartItem.donationProduct.name }}</h3>
                                            <p
                                                class="color-theme-padrao"
                                                v-html="cartItem.donationProduct.description"
                                            ></p>
                                        </v-flex>
                                    </v-layout>
                                    <template v-if="cartItem.donationProduct.showQuestions">
                                        <template
                                            v-for="(donationProductQuestion, index) in cartItem.donationProduct.donationProductQuestionList.filter((x) => x.active == true)"
                                        >
                                            <InputText
                                                v-if="!donationProductQuestion.hasAlternatives"
                                                ref="donationProductAnswerInput"
                                                :key="index"
                                                type="text"
                                                :textLabel="donationProductQuestion.question"
                                                v-model="donationProductQuestion.answer"
                                                invalidInputMessage="Entre com uma resposta válida"
                                            />

                                            <InputSelect
                                                v-else
                                                ref="donationProductSelectAnswerInput"
                                                :items="donationProductQuestion.donationProductQuestionAlternatives"
                                                valueAttribute="description"
                                                textAttribute="description"
                                                :key="index"
                                                type="text"
                                                :textLabel="donationProductQuestion.question"
                                                v-model="donationProductQuestion.answer"
                                                invalidInputMessage="Escolha uma opção"
                                            />
                                        </template>
                                    </template>
                                </v-flex>
                            </v-flex>
                        </template>
                    </v-flex>
                    <div class="add-project bg-white">
                        <button
                            @click="addMoreProjects(); $gtag('send', 'event', 'Add Mais Projetos', 'Carrinho Doação', 'Adicionar mais projetos')"
                        >
                            <i class="fal fa-plus"></i>
                            {{ "Adicionar mais " + itemsOrProjects }}
                        </button>
                    </div>
                </v-layout>
                <v-flex box-payment-cart>
                    <div class="bg-white pa-4 column-center">
                        <div class="project-for-cart display-flex mb-2">
                            <Icon name="fal fa-shopping-cart" size="47" colorFont="#fa6e6e" />
                            <div class="pl-3">
                                <h2 class="fw-400 ma-0 color-theme-texto">
                                    <span>{{ campaignLandingPageConfig.campaign.campaignTypeId == CampaignTypes.Ticket ? "Doar para" : "Doar para" }}</span>
                                    {{ cart.cartItems.length == 1 ? cart.cartItems.length + " " + itemOrProject : cart.cartItems.length + " " + itemsOrProjects }}
                                </h2>
                                <p class="color-theme-texto">
                                    Nós lhe enviaremos atualizações das organizações sobre o seu
                                    impacto!
                                </p>
                            </div>
                        </div>
                        <!-- Por enquanto não haverá doação em nome de terceiro -->
                        <button
                            v-show="false"
                            @click="openSimulationAlertCart(); $gtag('send', 'event', 'Carrinho', 'clicou', 'Doar em nome de algueum')"
                            class="v-btn--large btn-cart-present"
                        >
                            <Icon name="fal fa-gift" size="16" colorFont="#FFB400" class="pr-2" />Quero doar em nome de alguém
                        </button>
                        <button
                            v-show="this.hasProjectIncentived"
                            @click="toggleSimulation(); $gtag('send', 'event', 'Simulador carrinho', 'Simulacao carrinho', 'Simulador carrinho')"
                            class="btn-cart-simulation mr-3"
                        >
                            <Icon
                                name="fal fa-calculator-alt"
                                size="16"
                                colorFont="#fff"
                                class="pr-2"
                            />
                            {{ this.getSimulationResult() > 0 ? "Nova simulação" : "Faça uma simulação" }}
                        </button>
                        <AucAlert
                            ref="confirmationComponent"
                            icon="fal fa-calculator-alt"
                            :title="`Já sabe qual o seu potencial de doação para um projeto incentivado? Você pode direcionar até ${simulatorPercentage} do imposto devido* para o projeto selecionado.`"
                            confirmButtonText="Faça uma simulação"
                            cancelButtonText="Não, obrigado"
                            @confirm="toggleSimulation"
                            :withStorage="true"
                            :startOpen="this.hasProjectIncentived && this.getSimulationResult() == 0"
                        />
                    </div>
                    <div>
                        <v-tabs
                            v-model="cart.paymentMethodId"
                            class="tab-payment tabs-cart"
                            centered
                            color="white"
                            dark
                            icons-and-text
                        >
                            <v-tabs-slider color="yellow"></v-tabs-slider>
                            <v-tab v-show="false" class="pa-0 ma-0" style="min-width: 0px" />

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-tab
                                        class="tabs"
                                        :href="'#' + PaymentMethod.PayrollDeduction"
                                        v-if="containsPaymentMethodAndFitsTheChosenRecurrencePeriod(PaymentMethod.PayrollDeduction)"
                                        v-on="on"
                                        @click="onClickTab(PaymentMethod.PayrollDeduction); $gtag('send', 'event', 'Carrinho', 'clicou', 'Débito em Folha'); $gtag('send', 'pageview', '/carrinho/debito-folha')"
                                    >
                                        <Icon
                                            name="fas fa-money-check-alt"
                                            size="16"
                                            colorFont="#C3C6D5"
                                            class="pr-2"
                                        />
                                        <span>Folha de pagamento</span>
                                    </v-tab>
                                </template>
                                <span>Folha de pagamento</span>
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-tab
                                        class="tabs"
                                        :href="'#' + PaymentMethod.CreditCard"
                                        :disabled="cart.cartItems.length < 1"
                                        v-if="containsPaymentMethodAndFitsTheChosenRecurrencePeriod(PaymentMethod.CreditCard)"
                                        v-on="on"
                                        @click="onClickTab(PaymentMethod.CreditCard); $gtag('send', 'pageview', '/carrinho/cartao-credito')"
                                    >
                                        <Icon
                                            name="fas fa-credit-card"
                                            size="16"
                                            colorFont="#C3C6D5"
                                            class="pr-2"
                                        />
                                        <span>Cartão de Crédito</span>
                                    </v-tab>
                                </template>
                                <span>Cartão de Crédito</span>
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-tab
                                        class="tabs"
                                        :href="'#' + PaymentMethod.Ticket"
                                        :disabled="cart.cartItems.length < 1"
                                        v-if="containsPaymentMethodAndFitsTheChosenRecurrencePeriod(PaymentMethod.Ticket)"
                                        v-on="on"
                                        @click="onClickTab(PaymentMethod.Ticket); $gtag('send', 'pageview', '/carrinho/boleto')"
                                    >
                                        <Icon
                                            name="fas fa-barcode-alt"
                                            size="16"
                                            colorFont="#C3C6D5"
                                            class="pr-2"
                                        />
                                        <span>Boleto</span>
                                    </v-tab>
                                </template>
                                <span>Boleto</span>
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-tab
                                        class="tabs"
                                        :href="'#' + PaymentMethod.AccountDebit"
                                        :disabled="cart.cartItems.length < 1"
                                        v-if="containsPaymentMethodAndFitsTheChosenRecurrencePeriod(PaymentMethod.AccountDebit)"
                                        v-on="on"
                                        @click="onClickTab(PaymentMethod.AccountDebit); $gtag('send', 'event', 'Carrinho', 'clicou', 'Débito em Conta')"
                                    >
                                        <Icon
                                            name="fas fa-file-invoice-dollar"
                                            size="16"
                                            colorFont="#C3C6D5"
                                            class="pr-2"
                                        />
                                        <span>Débito em conta</span>
                                    </v-tab>
                                </template>
                                <span>Débito em conta</span>
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-tab
                                        class="tabs"
                                        :href="'#' + PaymentMethod.Pix"
                                        :disabled="cart.cartItems.length < 1"
                                        v-if="containsPaymentMethodAndFitsTheChosenRecurrencePeriod(PaymentMethod.Pix)"
                                        v-on="on"
                                        @click="onClickTab(PaymentMethod.Pix)"
                                    >
                                        <i class="pr-2" style="color: #c3c6d5">
                                            <svg
                                                width="21"
                                                height="21"
                                                viewBox="0 0 21 21"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M4.83025 5.30642C5.64648 5.30642 6.41419 5.61958 6.99139 6.18805L10.1233 9.27459C10.3488 9.49694 10.7171 9.49777 10.9432 9.27433L14.0637 6.1993C14.6409 5.63083 15.4086 5.31767 16.2248 5.31767H16.6007L12.6373 1.41231C11.4029 0.196059 9.40173 0.196059 8.16749 1.41231L4.21548 5.30643L4.83025 5.30642ZM16.2249 16.1824C15.4086 16.1824 14.6409 15.8693 14.0637 15.3008L10.9432 12.226C10.7241 12.0095 10.3423 12.0102 10.1233 12.226L6.99143 15.312C6.41422 15.8805 5.64651 16.1937 4.83029 16.1937H4.21533L8.16753 20.0879C9.40176 21.3041 11.403 21.3041 12.6373 20.0879L16.6007 16.1824H16.2249ZM17.4839 6.1879L19.879 8.54791C21.1133 9.76415 21.1133 11.7359 19.879 12.9522L17.4839 15.3122C17.4312 15.2914 17.3741 15.2784 17.3138 15.2784H16.2248C15.6618 15.2784 15.1107 15.0534 14.7129 14.6611L11.5925 11.5866C11.027 11.0289 10.0402 11.029 9.47408 11.5864L6.34222 14.6724C5.94439 15.0646 5.39326 15.2896 4.83027 15.2896H3.49125C3.43413 15.2896 3.38076 15.3028 3.33028 15.3215L0.925749 12.9522C-0.308583 11.736 -0.308583 9.76416 0.925749 8.54791L3.33038 6.1785C3.38076 6.1972 3.43413 6.21047 3.49125 6.21047H4.83027C5.39326 6.21047 5.94439 6.43539 6.34222 6.82775L9.47438 9.91402C9.7663 10.2015 10.1497 10.3454 10.5334 10.3454C10.9168 10.3454 11.3005 10.2015 11.5925 9.91374L14.7129 6.83899C15.1107 6.44671 15.6618 6.2217 16.2248 6.2217H17.3138C17.3741 6.2217 17.4312 6.20871 17.4839 6.1879Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </i>
                                        <span>Pix</span>
                                    </v-tab>
                                </template>
                                <span>Pague com Pix</span>
                            </v-tooltip>

                            <v-tab-item
                                style="background-color: transparent !important; border: none; box-shadow: none;"
                            >
                                <div class="alert-login-cart">
                                    <i class="fas fa-share fa-rotate-270"></i>
                                    Selecione acima o meio
                                    <br />de pagamento desejado
                                </div>
                            </v-tab-item>

                            <v-tab-item
                                class="px-4 bg-white"
                                :value="PaymentMethod.PayrollDeduction.toString()"
                                v-if="logged"
                            >
                                <div class="box-total">
                                    <div class="mw-total price">
                                        <span
                                            class="color-theme-texto"
                                        >{{ campaignLandingPageConfig.campaign.campaignTypeId == CampaignTypes.Ticket ? "Total a ser doado:" : "Total a ser doado:" }}</span>
                                        <div class="color-theme-texto">
                                            R$
                                            <h3
                                                class="mr-0 notranslate"
                                            >{{ formatterHelper.formatMoney(cartTotalPrice, 0) }},⁰⁰</h3>
                                            <span class="color-theme-primaria">{{ recurrenceText }}</span>
                                        </div>
                                    </div>
                                    <!-- aparece somente se usuario inserir valor menor -->
                                    <div
                                        class="text-xs-right f-size-12 mw-total"
                                        v-if="cartTotalPrice > 0 && cartTotalPrice < campaignLandingPageConfig.campaign.minimumDonationValue"
                                    >
                                        <span>Mínimo para doação R${{ formatterHelper.formatMoney(campaignLandingPageConfig.campaign.minimumDonationValue, 0) }}</span>
                                    </div>
                                    <div
                                        class="text-xs-right f-size-12 mw-total"
                                        v-if="selectDollar"
                                    >
                                        <span>* You will be charged in Brazillian Reais. It will show up as an international payment on your credit card bill.</span>
                                    </div>
                                    <div class="mw-total">
                                        <div :id="parcelamentsInputId">
                                            <InputSelect
                                                ref="recurrencePeriodInput"
                                                v-model="cart.donationRecurrencePeriodId"
                                                :textLabel="'Como deseja fazer esta ' + donationOrPurchase + ':'"
                                                helpLabel="Escolha se sua doação será única ou recorrente"
                                                :items="campaignDonationRecurrencePeriodList"
                                                valueAttribute="id"
                                                textAttribute="name"
                                                invalidInputMessage="Escolha se sua doação será única ou recorrente"                                                
                                            ></InputSelect>
                                            <InputSelect                                                
                                                v-model="cart.parcelamentId"
                                                textLabel="Selecione a quantidade de parcelas:"
                                                helpLabel="Escolha o parcelamento da sua doação"
                                                :items="parcelamentsDivided"
                                                valueAttribute="id"
                                                textAttribute="description"
                                                invalidInputMessage="Escolha o parcelamento da sua doação"
                                                v-if="hasPayRollParcelament"
                                            ></InputSelect>
                                        </div>
                                        <div class="box-info-data-payment c-text-dark">
                                            <div
                                                v-if="hasCompany"
                                                class="display-flex justify-space-between"
                                            >
                                                <div class="text-color">Empresa</div>
                                                <div class="text-color">CNPJ</div>
                                            </div>
                                            <div
                                                v-if="hasCompany"
                                                class="display-flex justify-space-between data"
                                            >
                                                <div class="c-text-dark">{{ companyName }}</div>
                                                <div class="c-text-dark">{{ companyCNPJ | cnpj }}</div>
                                            </div>
                                            <p v-if="hasCompany">{{ textCompany }}</p>
                                            <hr class="sm my-3" />
                                            <div
                                                class="check-terms"
                                                @click="openConsentTerm(PaymentMethod.PayrollDeduction); $gtag('send', 'event', 'LiTermoAnuência FolhaPagamento', 'Carrinho Doação', 'Termo de Anuência')"
                                            >
                                                <v-checkbox
                                                    :value="terms"
                                                    :disabled="true"
                                                    label="Declaro que li e concordo com o Termo de Anuência"
                                                ></v-checkbox>
                                            </div>
                                            <hr class="sm my-3" />
                                            <button
                                                class="btn-boleto fw-600"
                                                @click="checkout(); $gtag('send', 'event', 'Carrinho', 'clicou', 'Concluir doação débito em folha')"
                                                :disabled="cart.cartItems.length < 1"
                                            >
                                                <Icon
                                                    name="fas fa-money-check-alt"
                                                    size="16"
                                                    colorFont="#fff"
                                                    class="mr-2 text-white fw-600"
                                                />
                                                Concluir {{ donationOrPurchase }} com débito em folha
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </v-tab-item>
                            <v-tab-item
                                class="px-4 bg-white"
                                :value="PaymentMethod.CreditCard.toString()"
                                v-if="logged"
                            >
                                <div class="box-total">
                                    <div class="mw-total price">
                                        <span
                                            class="color-theme-texto"
                                        >{{ campaignLandingPageConfig.campaign.campaignTypeId == CampaignTypes.Ticket ? "Total a ser doado:" : "Total a ser doado:" }}</span>
                                        <div class="color-theme-texto">
                                            R$
                                            <h3
                                                class="mr-0 notranslate"
                                            >{{ formatterHelper.formatMoney(cartTotalPrice, 0) }},⁰⁰</h3>
                                            <span class="color-theme-primaria">{{ recurrenceText }}</span>
                                        </div>
                                    </div>
                                    <!-- aparece somente se usuario inserir valor menor -->
                                    <div
                                        class="text-xs-right f-size-12 mw-total"
                                        v-if="cartTotalPrice > 0 && cartTotalPrice < minimumPeriodDonationValue"
                                    >
                                        <span>Mínimo para doação R${{ formatterHelper.formatMoney(minimumPeriodDonationValue, 0) }}</span>
                                    </div>
                                    <div
                                        class="text-xs-right f-size-12 mw-total"
                                        v-if="selectDollar"
                                    >
                                        <span>* You will be charged in Brazillian Reais. It will show up as an international payment on your credit card bill.</span>
                                    </div>
                                    <div class="mw-total">
                                        <InputSelect
                                            ref="recurrencePeriodInput"
                                            v-model="cart.donationRecurrencePeriodId"
                                            :textLabel="'Como deseja fazer esta ' + donationOrPurchase + ':'"
                                            helpLabel="Escolha se sua doação será única ou recorrente"
                                            :items="campaignDonationRecurrencePeriodList"
                                            valueAttribute="id"
                                            textAttribute="name"
                                            invalidInputMessage="Escolha se sua doação será única ou recorrente"
                                            v-if="cart.paymentMethodId == paymentMethods.CreditCard"
                                            @input="keyCreditCardParcelamentChange($event)"
                                        ></InputSelect>
                                    </div>
                                    <div :key="`recurrence-${keyCreditCardParcelament}`" class="mw-total" v-if="this.cartTotalPrice > 0 && verifyParcelamentPaymentMethod()">
                                        <span class="color-theme-texto">Selecione a quantidade de parcelas:</span>
                                        <span
                                            class="color-theme-texto"
                                            style="float: right; font-weight: bold"
                                        >Valor mínimo da parcela: {{ minimumParcelamentValue | FormatMoney }}</span>
                                        <div :key="`parcelament-${keyCreditCardParcelament}`">
                                            <InputSelect
                                                :items="GethowMany"
                                                v-model="cart.creditCardParcelament"
                                                :disabled="false"
                                                valueAttribute="quantidade"
                                                textAttribute="name"
                                                style="width: 50%"
                                                :key="GethowManyTicketParcelament"
                                                @input="showParcelamentMessage(cart.creditCardParcelament)"
                                            ></InputSelect>
                                        </div>
                                    </div>
                                    <div
                                        v-if="taxOwner == taxOwners.Person && cartTotalPrice > 0"
                                        class="mw-total"
                                    >
                                        <div class="ml-2">
                                            <span
                                                class="color-theme-texto"
                                            >Taxa do cartão de crédito:</span>
                                            <span
                                                class="color-theme-texto"
                                                style="float: right; font-weight: bold"
                                            >R${{ formatterHelper.formatMoney(cartTaxMethodPayment(), 2) }}</span>
                                        </div>

                                        <div class="ml-2">
                                            <span class="color-theme-texto">Valor total:</span>
                                            <span
                                                class="color-theme-texto notranslate"
                                                style="float: right; font-weight: bold"
                                            >R${{ getParcelamentTotalValue }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="box-info-data-payment mw-total"
                                    v-if="cart.paymentMethodId == paymentMethods.CreditCard"
                                >
                                    <h4 class="title-boleto">Dados do Titular</h4>
                                    <div class="form-grid" @click.once="gaLightBoxDadosTitular()">
                                        <v-switch
                                            v-model="addNewCard"
                                            inset
                                            :label="'Adicionar Novo Cartão de Crédito'"
                                        ></v-switch>
                                        <div v-if="!addNewCard">
                                            <h2 class="fw-400 color-theme-texto">Selecione o cartão</h2>
                                            <table class="v-datatable v-table theme--light">
                                                <thead>
                                                    <tr>
                                                        <th class="text-sm-center" width="40">#</th>
                                                        <th class="text-sm-left">Número</th>
                                                        <th class="text-sm-left">Nome</th>
                                                        <th class="text-sm-left">Bandeira</th>
                                                        <th class="text-sm-center" width="40">#</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="notranslate">
                                                    <tr
                                                        v-for="item in creditCards"
                                                        :key="item.id"
                                                        :style="item.id === selectedCardId ? 'background: rgba(104, 187, 108, 0.1)' : ''"
                                                        @click="selectCreditCard(item.id, item)"
                                                    >
                                                        <td>
                                                            <a>
                                                                <v-icon
                                                                    v-if="item.id === selectedCardId"
                                                                    color="green lighten-1"
                                                                >radio_button_checked</v-icon>
                                                                <v-icon
                                                                    v-else
                                                                    color="gray lighten-1"
                                                                >radio_button_unchecked</v-icon>
                                                            </a>
                                                        </td>
                                                        <td>**** **** **** {{ item.lastDigits }}</td>
                                                        <td>{{ item.cardName }}</td>
                                                        <td v-if="item.firstDigits[0] == '4'">Visa</td>
                                                        <td
                                                            v-if="item.firstDigits[0] == '5'"
                                                        >Master Card</td>
                                                        <td>
                                                            <v-btn
                                                                small
                                                                flat
                                                                fab
                                                                @click="deleteCreditCard(item.id)"
                                                            >
                                                                <Icon
                                                                    name="fal fa-trash"
                                                                    size="20"
                                                                    colorFont="#cfd2dd"
                                                                />
                                                            </v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div v-if="addNewCard">
                                            <h2
                                                class="fw-400 color-theme-texto"
                                            >Adicionar Novo Cartão.</h2>
                                            <v-layout row wrap style="margin: auto -16px">
                                                <v-flex xs12 md6 pl-3>
                                                    <InputText
                                                        ref="cardNumberInput"
                                                        required="true"
                                                        type="tel"
                                                        textLabel="Número do cartão"
                                                        autocomplete="cc-number"
                                                        place-holder="0000 0000 0000 0000"
                                                        v-model="cart.checkoutRequest.creditCardInfo.cardNumber"
                                                        mask="#### #### #### ####"
                                                        invalidInputMessage="Preencha o número do cartão"
                                                        :validation="creditCardNumberValidation"
                                                    />
                                                </v-flex>
                                                <v-flex xs12 md6 px-3>
                                                    <InputText
                                                        ref="holderNameInput"
                                                        required="true"
                                                        type="text"
                                                        textLabel="Nome"
                                                        autocomplete="cc-name"
                                                        place-holder="Como está escrito no cartão"
                                                        v-model="cart.checkoutRequest.creditCardInfo.holder.name"
                                                        :labelCharLimit="100"
                                                        invalidInputMessage="Preencha o nome que consta no cartão"
                                                        :validation="validateCardName"
                                                        @input="nameToUpperCase()"
                                                    />
                                                </v-flex>
                                                <v-flex xs12 md3 pl-3>                                                    
                                                    <InputSelect 
                                                        ref="expirationMonthInput"
                                                        required="true"
                                                        :items="monthsList"
                                                        v-model="cart.checkoutRequest.creditCardInfo.expirationMonth"
                                                        textLabel="Mês de vencimento"
                                                        invalidInputMessage="Preencha o mês de vencimento"
                                                    />                                                    
                                                </v-flex>
                                                <v-flex xs12 md3 pl-3>
                                                    <InputSelect 
                                                        ref="expirationYearInput"
                                                        required="true"
                                                        :items="yearsList"
                                                        v-model="cart.checkoutRequest.creditCardInfo.expirationYear"
                                                        textLabel="Ano de vencimento"
                                                        invalidInputMessage="Preencha o ano de vencimento"
                                                    />
                                                </v-flex>
                                                <v-flex xs12 md6 px-3>
                                                    <InputText
                                                        ref="cvvInput"
                                                        type="tel"
                                                        required="true"
                                                        textLabel="Código de Segurança"
                                                        place-holder="CVV"
                                                        autocomplete="cc-csc"
                                                        v-model="cart.checkoutRequest.creditCardInfo.cvv"
                                                        mask="####"
                                                        invalidInputMessage="Preencha o código de segurança"
                                                        :validation="cvvValidation"
                                                    />
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row wrap>
                                                <v-switch
                                                    v-model="saveCreditCard"
                                                    inset
                                                    :label="'Salvar novo cartão?'"
                                                ></v-switch>
                                            </v-layout>
                                        </div>
                                        <template v-if="!isPaymentSimplified">
                                            <v-flex xs12 md6>
                                                <InputText
                                                    ref="holderCPFInput"
                                                    type="tel"
                                                    :textLabel="campaignLandingPageConfig.campaign.permitCnpjDonation ? 'CPF/CNPJ' : 'CPF'"
                                                    :place-holder="`Digite o ${campaignLandingPageConfig.campaign.permitCnpjDonation ? 'CPF/CNPJ' : 'CPF' }`"
                                                    v-model="cart.checkoutRequest.creditCardInfo.holder.CPF"
                                                    :mask="campaignLandingPageConfig.campaign.permitCnpjDonation ? ['###.###.###-##', '##.###.###/####-##'] : ['###.###.###-##']"
                                                    :invalidInputMessage="`Preencha o ${campaignLandingPageConfig.campaign.permitCnpjDonation ? 'CPF/CNPJ' : 'CPF' } do títular`"
                                                />
                                            </v-flex>
                                        </template>
                                    </div>
                                    <hr class="sm my-2" />
                                    <v-layout row wrap>
                                        <div
                                            class="check-terms"
                                            @click="openConsentTerm(PaymentMethod.CreditCard); $gtag('send', 'event', 'LiTermoAnuência CartãoCrédito', 'Carrinho Doação', 'Termo de Anuência')"
                                        >
                                            <v-checkbox
                                                :value="terms"
                                                :disabled="true"
                                                label="Declaro que li e concordo com o Termo de Anuência"
                                            ></v-checkbox>
                                        </div>
                                    </v-layout>
                                    <hr class="sm my-3" />
                                    <button
                                        class="btn-boleto mt-2 fw-700"
                                        @click="creditCardCheckout(); $gtag('send', 'event', 'Forma de pagamento Cartao de credito', 'Forma de pagamento cartao de credito', 'Cartao de credito')"
                                        :disabled="cart.cartItems.length < 1"
                                    >
                                        <Icon
                                            name="fas fa-credit-card"
                                            size="16"
                                            colorFont="#fff"
                                            class="pr-2 text-white"
                                        />
                                        Concluir {{ donationOrPurchase }} cartão de crédito
                                    </button>
                                </div>
                            </v-tab-item>
                            <v-tab-item
                                class="px-4 bg-white"
                                :value="PaymentMethod.Ticket.toString()"
                                v-if="logged"
                            >
                                <div class="box-info-data-payment">
                                </div>
                                <div class="box-total">
                                    <v-alert
                                        :value="showTicketAlert"
                                        type="error"
                                        class="alert-cart c-white"
                                    >
                                        Para emitir o boleto com o seu endereço de cobrança
                                        <a
                                            :href="buildProfileURL()"
                                            style="color: #e00000"
                                        >Clique aqui</a>
                                        para cadastrá-lo no seu perfil.
                                    </v-alert>
                                    <div class="mw-total price">
                                        <span
                                            class="color-theme-texto"
                                        >Total a ser doado:</span>
                                        <div class="color-theme-texto">
                                            R$
                                            <h3
                                                class="mr-0 notranslate"
                                            >{{ formatterHelper.formatMoney(cartTotalPrice, 0) }},⁰⁰</h3>
                                            <span class="color-theme-primaria">{{ recurrenceText }}</span>
                                        </div>
                                    </div>

                                    <div
                                        v-if="taxOwner == taxOwners.Person && cartTotalPrice > 0"
                                        class="mw-total"
                                    >
                                        <div>
                                            <span class="color-theme-texto">Taxa do boleto:</span>
                                            <span
                                                class="color-theme-texto notranslate"
                                                style="float: right; font-weight: bold"
                                            >R${{ formatterHelper.formatMoney(cartTaxMethodPayment() * (this.cart.creditCardParcelament > 1 ? this.cart.creditCardParcelament : 1), 2) }}</span>
                                        </div>

                                        <div>
                                            <span class="color-theme-texto">Valor total:</span>
                                            <span
                                                class="color-theme-texto notranslate"
                                                style="float: right; font-weight: bold"
                                            >R${{ getParcelamentTotalValue }}</span>
                                        </div>
                                    </div>
                                    <div class="mw-total" v-if="cart.paymentMethodId == paymentMethods.Ticket" @click="resetCreditCardParcelament()">
                                        <InputSelect
                                            ref="recurrencePeriodInput"
                                            v-model="cart.donationRecurrencePeriodId"
                                            :textLabel="'Como deseja fazer esta ' + donationOrPurchase + ':'"
                                            helpLabel="Escolha se sua doação será única ou recorrente"
                                            :items="campaignDonationRecurrencePeriodList"
                                            valueAttribute="id"
                                            textAttribute="name"
                                            invalidInputMessage="Escolha se sua doação será única ou recorrente"
                                        ></InputSelect>
                                    </div>
                                    <div class="mw-total" v-if="this.cartTotalPrice > 0 && verifyParcelamentPaymentMethod()">
                                        <span class="color-theme-texto">Selecione a quantidade de parcelas:</span>
                                        <span
                                            :key="GethowManyTicketParcelament"
                                            class="color-theme-texto"
                                            style="float: right; font-weight: bold"
                                        >Valor mínimo da parcela: {{ minimumParcelamentValue | FormatMoney }}</span>
                                        <div>
                                            <InputSelect
                                                :items="GethowMany"
                                                v-model="cart.creditCardParcelament"
                                                :disabled="false"
                                                valueAttribute="quantidade"
                                                textAttribute="name"
                                                style="width: 50%"
                                                :key="GethowManyTicketParcelament"
                                                @input="showParcelamentMessage(cart.creditCardParcelament); keyCreditCardParcelament += 1"
                                            ></InputSelect>
                                        </div>
                                    </div>
                                    <div class="mw-total" v-if="cart.paymentMethodId == paymentMethods.Ticket 
                                                                && (cart.donationRecurrencePeriodId > 1 || (cart.creditCardParcelament > 1))">
                                        <InputSelect
                                            ref="paymentDay"
                                            textLabel="Selecione o dia de vencimento do boleto"
                                            textAttribute="name"
                                            valueAttribute="id"
                                            :items="daysOfMonth"
                                            required="true"
                                            v-model="cart.checkoutRequest.ticketInfo.dayOfMonth"
                                            style="width: 30%"
                                            invalidInputMessage="Selecione o dia de vencimento do boleto"
                                        />
                                    </div>
                                </div>
                                <div v-if="logged">
                                    <v-card-text>
                                        <div>
                                            <v-layout row wrap>
                                                <div
                                                    class="check-terms"
                                                    @click="openConsentTerm(PaymentMethod.Ticket); $gtag('send', 'event', 'LiTermoAnuência Boleto', 'Carrinho Doação', 'Termo de Anuência')"
                                                >
                                                    <v-checkbox
                                                        :value="terms"
                                                        :disabled="true"
                                                        label="Declaro que li e concordo com o Termo de Anuência"
                                                    ></v-checkbox>
                                                </div>
                                            </v-layout>
                                            <hr class="sm my-3" />
                                            <button
                                                class="btn-boleto bg-orange fw-600"
                                                @click="generateTicket(); $gtag('send', 'event', 'Forma de pagamento Boleto', 'Forma de pagamento boleto', 'Boleto')"
                                            >
                                                <Icon
                                                    name="fal fa-barcode-alt"
                                                    size="16"
                                                    colorFont="#fff"
                                                    class="mr-2"
                                                />Gerar Boleto Bancário
                                            </button>
                                        </div>
                                        <!-- </GenerateTicket> -->
                                    </v-card-text>
                                </div>
                            </v-tab-item>
                            <v-tab-item
                                class="px-4 bg-white"
                                :value="PaymentMethod.AccountDebit.toString()"
                                v-if="logged"
                            >
                                <div class="box-total">
                                    <div class="mw-total price">
                                        <span
                                            class="color-theme-texto"
                                        >{{ campaignLandingPageConfig.campaign.campaignTypeId == CampaignTypes.Ticket ? "Total a ser doado:" : "Total a ser doado:" }}</span>
                                        <div class="color-theme-texto">
                                            R$
                                            <h3
                                                class="mr-0"
                                            >{{ formatterHelper.formatMoney(cartTotalPrice, 0) }},⁰⁰</h3>
                                            <span class="color-theme-primaria">{{ recurrenceText }}</span>
                                        </div>
                                    </div>
                                    <!-- aparece somente se usuario inserir valor menor -->
                                    <div
                                        class="text-xs-right f-size-12 mw-total"
                                        v-if="cartTotalPrice > 0 && cartTotalPrice < minimumPeriodDonationValue"
                                    >
                                        <span>Mínimo para doação R$ {{ formatterHelper.formatMoney(minimumPeriodDonationValue, 0) }}</span>
                                    </div>
                                    <div
                                        class="text-xs-right f-size-12 mw-total"
                                        v-if="selectDollar"
                                    >
                                        <span>* You will be charged in Brazillian Reais. It will show up as an international payment on your credit card bill.</span>
                                    </div>
                                    <div class="mw-total">
                                        <InputSelect
                                            ref="recurrencePeriodInput"
                                            v-model="cart.donationRecurrencePeriodId"
                                            :textLabel="'Como deseja fazer esta ' + donationOrPurchase + ':'"
                                            helpLabel="Escolha se sua doação será única ou recorrente"
                                            :items="campaignDonationRecurrencePeriodList"
                                            valueAttribute="id"
                                            textAttribute="name"
                                            invalidInputMessage="Escolha se sua doação será única ou recorrente"
                                            v-if="cart.paymentMethodId == paymentMethods.AccountDebit"
                                        ></InputSelect>
                                    </div>
                                    <div
                                        v-if="taxOwner == taxOwners.Person && cartTotalPrice > 0"
                                        class="mw-total"
                                    >
                                        <div class="ml-2">
                                            <span class="color-theme-texto">Taxa do débito em conta:</span>
                                            <span
                                                class="color-theme-texto"
                                                style="float: right; font-weight: bold"
                                            >R$ {{ formatterHelper.formatMoney(cartTaxMethodPayment(), 2) }}</span>
                                        </div>

                                        <div class="ml-2">
                                            <span class="color-theme-texto">Valor total:</span>
                                            <span
                                                class="color-theme-texto"
                                                style="float: right; font-weight: bold"
                                            >R$ {{ formatterHelper.formatMoney((cartTotalPrice + cartTaxMethodPayment()* (this.cart.creditCardParcelament ? this.cart.creditCardParcelament : 1)), 2) }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="box-info-data-payment mw-total"
                                    v-if="cart.paymentMethodId == paymentMethods.AccountDebit"
                                >
                                    <h4 class="title-boleto">Dados da Conta</h4>
                                    <div class="form-grid">
                                        <v-layout row wrap align-start>
                                            <v-flex xs12 md6>
                                                <InputText
                                                    ref="holderNameInput"
                                                    type="text"
                                                    textLabel="Nome do titular da conta"
                                                    place-holder="Nome completo do titular"
                                                    v-model="cart.checkoutRequest.debitAccountInfo.holder.name"
                                                    invalidInputMessage="Preencha o nome do titular"
                                                    @input="nameToUpperCase()"
                                                />
                                            </v-flex>
                                            <v-flex xs12 md6>
                                                <InputText
                                                    ref="holderCPFInput"
                                                    type="tel"
                                                    textLabel="CPF"
                                                    place-holder="000.000.000-00"
                                                    v-model="cart.checkoutRequest.debitAccountInfo.holder.CPF"
                                                    mask="###.###.###-##"
                                                    invalidInputMessage="Preencha o CPF do títular"
                                                />
                                            </v-flex>
                                            <v-flex xs8 md8>
                                                <InputSelect
                                                    ref="paymentCompanyCodeInput"
                                                    textLabel="Banco"
                                                    textAttribute="name"
                                                    valueAttribute="code"
                                                    :items="debitAccountBanks"
                                                    v-model="cart.checkoutRequest.debitAccountInfo.paymentCompanyCode"
                                                    invalidInputMessage="Selecione um banco"
                                                />
                                            </v-flex>
                                            <v-flex xs4 md4>
                                                <InputSelect
                                                    ref="paymentCompanyCodeInput"
                                                    textLabel="Dia para o débito"
                                                    textAttribute="name"
                                                    valueAttribute="id"
                                                    :items="daysOfMonth"
                                                    v-model="cart.checkoutRequest.debitAccountInfo.dayOfMonth"
                                                    invalidInputMessage="Selecione o dia para débito"
                                                />
                                            </v-flex>
                                            <v-flex xs12 md5>
                                                <InputText
                                                    ref="bankBranchInput"
                                                    type="tel"
                                                    textLabel="Agência"
                                                    place-holder="0000"
                                                    v-model="cart.checkoutRequest.debitAccountInfo.bankBranch"
                                                    invalidInputMessage="Preencha com a agência"
                                                />
                                            </v-flex>
                                            <v-flex xs12 md5>
                                                <InputText
                                                    ref="bankAccountInput"
                                                    type="tel"
                                                    textLabel="Conta"
                                                    place-holder="0000"
                                                    v-model="cart.checkoutRequest.debitAccountInfo.bankAccount"
                                                    invalidInputMessage="Preencha com a conta"
                                                />
                                            </v-flex>
                                            <v-flex xs12 md2>
                                                <InputText
                                                    ref="bankAccountDigitInput"
                                                    type="tel"
                                                    textLabel="Dígito"
                                                    place-holder="0000"
                                                    v-model="cart.checkoutRequest.debitAccountInfo.bankAccountDigit"
                                                    invalidInputMessage="Dígito da conta"
                                                />
                                            </v-flex>
                                        </v-layout>
                                    </div>
                                    <hr class="sm my-2" />
                                    <v-layout row wrap>
                                        <div
                                            class="check-terms"
                                            @click="openConsentTerm(PaymentMethod.AccountDebit)"
                                        >
                                            <v-checkbox
                                                :value="terms"
                                                :disabled="true"
                                                label="Declaro que li e concordo com o Termo de Anuência"
                                            ></v-checkbox>
                                        </div>
                                    </v-layout>
                                    <hr class="sm my-3" />
                                    <button
                                        class="btn-boleto mt-2 fw-700"
                                        @click="debitAccountCheckout(); $gtag('send', 'event', 'Forma de pagamento Débito em conta', 'Forma de pagamento Débito em conta', 'Débito em conta')"
                                        :disabled="cart.cartItems.length < 1"
                                    >
                                        <Icon
                                            name="fas fa-money-check-alt"
                                            size="16"
                                            colorFont="#fff"
                                            class="pr-2 text-white"
                                        />
                                        Concluir {{ donationOrPurchase }} com débito em conta
                                    </button>
                                </div>
                            </v-tab-item>
                            <v-tab-item
                                class="px-4 bg-white pix"
                                :value="PaymentMethod.Pix.toString()"
                                v-if="logged"
                            >
                                <div class="box-total">
                                    <div class="mw-total price">
                                        <span class="color-theme-texto">Total a ser doado:</span>
                                        <div class="color-theme-texto">
                                            R$
                                            <h3
                                                class="mr-0 notranslate"
                                            >{{ formatterHelper.formatMoney(cartTotalPrice, 0) }},⁰⁰</h3>
                                        </div>
                                    </div>

                                    <div
                                        v-if="cartTotalPrice > 0"
                                        class="mw-total"
                                    >
                                        <div v-if="cartTaxMethodPayment() > 0">
                                            <span class="color-theme-texto">Taxa do pix:</span>
                                            <span
                                                class="color-theme-texto notranslate"
                                                style="float: right; font-weight: bold"
                                            >R$ {{ (taxOwner == taxOwners.Person) ? formatterHelper.formatMoney(cartTaxMethodPayment() * (this.cart.creditCardParcelament > 1 ? this.cart.creditCardParcelament : 1), 2) : '0,00' }}</span>
                                        </div>

                                        <div>
                                            <span class="color-theme-texto">Valor total:</span>
                                            <span
                                                class="color-theme-texto notranslate"
                                                style="float: right; font-weight: bold"
                                            >R$ {{ (taxOwner == taxOwners.Person) ? formatterHelper.formatMoney((cartTotalPrice + (cartTaxMethodPayment() * (this.cart.creditCardParcelament > 1 ? this.cart.creditCardParcelament : 1))), 2) : 
                                            formatterHelper.formatMoney(cartTotalPrice, 2)}}</span>
                                        </div>

                                        <div v-if="cart.paymentMethodId == paymentMethods.Pix"> 
                                            <InputSelect
                                                ref="recurrencePeriodInput"
                                                v-model="cart.donationRecurrencePeriodId"
                                                :textLabel="'Como deseja fazer esta ' + donationOrPurchase + ':'"
                                                helpLabel="Escolha se sua doação será única ou recorrente"
                                                :items="campaignDonationRecurrencePeriodList"
                                                valueAttribute="id"
                                                textAttribute="name"
                                                invalidInputMessage="Escolha se sua doação será única ou recorrente"
                                            ></InputSelect>
                                        </div>

                                        <div v-if="this.cartTotalPrice > 0 && verifyParcelamentPaymentMethod()">
                                            <span class="color-theme-texto">Selecione a quantidade de parcelas:</span>
                                            <span
                                                class="color-theme-texto"
                                                style="float: right; font-weight: bold"
                                            >Valor mínimo da parcela: {{ minimumParcelamentValue | FormatMoney }}</span>
                                            <div>
                                                <InputSelect
                                                    :items="GethowMany"
                                                    v-model="cart.creditCardParcelament"
                                                    :disabled="false"
                                                    valueAttribute="quantidade"
                                                    textAttribute="name"
                                                    style="width: 50%"
                                                    :key="GethowManyTicketParcelament"
                                                    @input="showParcelamentMessage(cart.creditCardParcelament); keyCreditCardParcelament += 1"
                                                ></InputSelect>
                                            </div>
                                        </div>

                                        <div v-if="cart.paymentMethodId == paymentMethods.Pix && (cart.creditCardParcelament > 1 || cart.donationRecurrencePeriodId > 1)">
                                            <InputSelect
                                                textLabel="Dia do vencimento"
                                                ref="pixpaymentDay"
                                                textAttribute="name"
                                                valueAttribute="id"
                                                :items="daysOfMonth"
                                                v-model="cart.checkoutRequest.pixInfo.dayOfMonth"
                                                style="width: 30%"
                                                required="true"
                                                invalidInputMessage="Selecione o dia de vencimento"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="logged">
                                    <v-card-text>
                                        <div>
                                            <v-layout row wrap>
                                                <div
                                                    class="check-terms"
                                                    @click="openConsentTerm(PaymentMethod.Pix); $gtag('send', 'event', 'LiTermoAnuência Pix', 'Carrinho Doação', 'Termo de Anuência');"
                                                >
                                                    <v-checkbox
                                                        :value="terms"
                                                        :disabled="true"
                                                        label="Declaro que li e concordo com o Termo de Anuência"
                                                    ></v-checkbox>
                                                </div>
                                            </v-layout>
                                            <hr class="sm my-3" />
                                            <button
                                                class="btn-boleto bg-orange fw-600"
                                                @click="pixCheckout(); $gtag('send', 'event', 'Forma de pagamento Pix', 'Forma de pagamento Pix', 'Pix');"
                                            >
                                                <svg
                                                    width="21"
                                                    height="21"
                                                    viewBox="0 0 21 21"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M4.83025 5.30642C5.64648 5.30642 6.41419 5.61958 6.99139 6.18805L10.1233 9.27459C10.3488 9.49694 10.7171 9.49777 10.9432 9.27433L14.0637 6.1993C14.6409 5.63083 15.4086 5.31767 16.2248 5.31767H16.6007L12.6373 1.41231C11.4029 0.196059 9.40173 0.196059 8.16749 1.41231L4.21548 5.30643L4.83025 5.30642ZM16.2249 16.1824C15.4086 16.1824 14.6409 15.8693 14.0637 15.3008L10.9432 12.226C10.7241 12.0095 10.3423 12.0102 10.1233 12.226L6.99143 15.312C6.41422 15.8805 5.64651 16.1937 4.83029 16.1937H4.21533L8.16753 20.0879C9.40176 21.3041 11.403 21.3041 12.6373 20.0879L16.6007 16.1824H16.2249ZM17.4839 6.1879L19.879 8.54791C21.1133 9.76415 21.1133 11.7359 19.879 12.9522L17.4839 15.3122C17.4312 15.2914 17.3741 15.2784 17.3138 15.2784H16.2248C15.6618 15.2784 15.1107 15.0534 14.7129 14.6611L11.5925 11.5866C11.027 11.0289 10.0402 11.029 9.47408 11.5864L6.34222 14.6724C5.94439 15.0646 5.39326 15.2896 4.83027 15.2896H3.49125C3.43413 15.2896 3.38076 15.3028 3.33028 15.3215L0.925749 12.9522C-0.308583 11.736 -0.308583 9.76416 0.925749 8.54791L3.33038 6.1785C3.38076 6.1972 3.43413 6.21047 3.49125 6.21047H4.83027C5.39326 6.21047 5.94439 6.43539 6.34222 6.82775L9.47438 9.91402C9.7663 10.2015 10.1497 10.3454 10.5334 10.3454C10.9168 10.3454 11.3005 10.2015 11.5925 9.91374L14.7129 6.83899C15.1107 6.44671 15.6618 6.2217 16.2248 6.2217H17.3138C17.3741 6.2217 17.4312 6.20871 17.4839 6.1879Z"
                                                        fill="currentColor"
                                                    />
                                                </svg>
                                                Gerar dados para o pix
                                            </button>
                                        </div>
                                        <!-- </GenerateTicket> -->
                                    </v-card-text>
                                </div>
                            </v-tab-item>
                        </v-tabs>
                    </div>

                    <div
                        v-if="logged"
                        class="f-600-column bg-white pa-4 display-flex justify-space-between"
                    >
                        <div class="box-quality-terms m-600-bottom-24 mb-5 color-theme-texto">
                            Ao fazer uma {{ donationOrPurchase }}, você concorda com nossos
                            <div>
                                <a
                                    href="javascript:;"
                                    class="color-theme-texto"
                                    @click="showServiceTerms = !showServiceTerms; $gtag('send', 'event', 'Termos de Uso', 'Carrinho Doação', 'Termos de uso')"
                                >Termos de uso</a>
                                e
                                <a
                                    class="color-theme-texto"
                                    href="javascript:;"
                                    @click="showPolicyTerms = !showPolicyTerms; $gtag('send', 'event', 'Política Privacidade', 'Carrinho Doação', 'Política de privacidade')"
                                >Política de Privacidade.</a>
                            </div>
                        </div>
                    </div>
                    <SimulationValueFloat
                        v-if="simulationValue > 0"
                        :dialog="true"
                        :valueToDiscount="0"
                        :suggestedValue="simulationValue"
                        @openSimulation="toggleSimulation"
                    ></SimulationValueFloat>
                </v-flex>
            </v-layout>
        </div>
        <ModalPix ref="modalPix" @payed="finishPix" />

        <AucAlert
            ref="removeCartItemAlert"
            :title="'Você tem certeza que deseja excluir esse ' + itemOrProject + ' do seu carrinho?'"
            confirmButtonText="Sim"
            cancelButtonText="Não"
            @confirm="removeCartItem(selectedCartItem)"
        />
        <AucAlert
            ref="confirmWithValueSimulation"
            icon="fal fa-calculator-alt"
            :title="textSimulationConfirm"
            confirmButtonText="Continuar"
            cancelButtonText="Alterar valor"
            @confirm="callPaymentMethod"
            :withStorage="false"
        /><AucAlert
            ref="parcelamentMessage"
            :title="getParcelamentMessageText()"
            confirmButtonText="Ok"
        />
    </div>
</template>
<script>
import { parse } from "date-fns";
/**
 * Componentes
 */
// import BorderSeparation from "@/components/BorderSeparation.vue";
import ConsentTerm from "@/components/ConsentTerm.vue";
import ProjectBox from "@/components/ProjectBox.vue";
import GenerateTicket from "@/components/GenerateTicket.vue";
import Simulation from "@/components/Simulation.vue";
import UseTermsModal from "@/components/UseTermsModal.vue";
import PrivacyPolicyTermsModal from "@/components/PrivacyPolicyTermsModal.vue";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import SimulationValueFloat from "@/components/SimulationValueFloat.vue";
import AucAlert from "@/components/AucAlert.vue";
import ModalSuccess from "@/components/Modal.vue";
import ModalPix from "@/components/ModalPix.vue";

/**
 * Services
 */
import CartService from "@/scripts/services/cart.service";
import CompanyService from "@/scripts/services/company.service";
import FileService from "@/scripts/services/file.service";
import LocationService from "@/scripts/services/location.service.js";
import ConfigManagerService from "@/scripts/services/configManager.service.js";
import { mapGetters, mapActions } from "vuex";
import ReceiverParcelamentService from "@/scripts/services/receiverParcelament.service.js"

/***
 * Helpers
 */
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import CommonHelper from "@/scripts/helpers/common.helper";

/**
 * Models
 */
import CreditCardCartDetails from "@/scripts/models/creditCardCartDetails.model";
import CheckoutRequest from "@/scripts/models/checkoutRequest.model";
import Cart from "@/scripts/models/cart.model";
import CreditCard from "@/scripts/models/creditCard.model";

/**
 * Enums
 */
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import CartStatus from "@/scripts/models/enums/cartStatus.enum";
import CreditCardCartDetailsStatus from "@/scripts/models/enums/creditCardCartDetailsStatus.enum";
import DonationRecurrencePeriod from "@/scripts/models/enums/donationRecurrencePeriod.enum";
import CampaignLandingPageConfig from "@/scripts/models/campaignLandingPageConfig.model";
import CampaignService from "@/scripts/services/campaign.service";
import ProjectService from "@/scripts/services/project.service";
import PersonService from "@/scripts/services/person.service";
import FileImage from "@/components/FileImage.vue";
import LoginBox from "@/components/login/LoginBox.vue";
import { Routes } from "@/scripts/models/enums/routes.enum";
import { TaxOwners } from "@/scripts/models/enums/taxOwners.enum";
import { ProjectTypes } from "@/scripts/models/enums/projectTypes.enum";
import { CampaignTypes } from "@/scripts/models/enums/campaignTypes.enum";
import PaymentMethodProvider from "@/scripts/models/enums/paymentMethodProvider.enum";
import { extendClass } from 'highcharts';

export default {
    extends: ValidatableComponent,
    components: {
        ProjectBox,
        LoginBox,
        GenerateTicket,
        Simulation,
        PrivacyPolicyTermsModal,
        UseTermsModal,
        FileImage,
        ConsentTerm,
        AucAlert,
        SimulationValueFloat,
        ModalSuccess,
        ModalPix,
        // BorderSeparation
    },
    props: {
        landingPageOptions: null,
    },
    data() {
        return {
            paymentMethods: PaymentMethod,
            taxOwners: TaxOwners,
            birthDate: "",
            keyCreditCardParcelament: 0,
            debitAccountBanks: null,
            countryItems: [{ id: "BRA", value: "Brasil" }],
            stateItems: [],
            qtde: 0,
            showAlert: false,
            tokenRecaptcha: "",
            textSimulation:
                `Já sabe qual o seu potencial de doação para um projeto incentivado? Você pode direcionar até ${this.simulatorPercentage} do imposto devido* para o projeto selecionado.`,
            doSimulation: "Faça uma simulação",
            textCompany: "",
            showPolicyTerms: false,
            showConsentTerm: false,
            showServiceTerms: false,
            cartService: null,
            companyService: new CompanyService(),
            fileService: new FileService(),
            campaignLandingPageConfig: new CampaignLandingPageConfig(),
            campaignService: new CampaignService(),
            projectService: new ProjectService(),
            configManagerService: new ConfigManagerService(),
            personService: new PersonService(),
            cart: null,
            formatterHelper: new FormatterHelper(),
            locationService: new LocationService(),
            terms: false,
            hasPayRollParcelament: false,
            hasCompany: false,
            companyCNPJ: '15.002.134/0001-1',
            companyName: '',
            pixEnabled: false,
            creditCardEnabled: false,
            ticketEnabled: false,
            payrollDeductionEnabled: false,
            debitAccountEnabled: false,
            hasProjectIncentived: false,
            CommonHelper: CommonHelper,
            routes: null,
            selectedCartItem: null,
            simulationValue: 0,
            confirmAlreadyOpen: false,
            actionCheckout: "",
            textSimulationConfirm: "",
            finishedCart: null,
            daysOfMonth: [],
            /**
             * Enumerador
             */
            PaymentMethod: PaymentMethod,
            DonationRecurrencePeriod: DonationRecurrencePeriod,
            CreditCardCartDetailsStatus: CreditCardCartDetailsStatus,
            creditCard: new CreditCard(),
            ProjectTypes: ProjectTypes,
            CampaignTypes: CampaignTypes,
            cepValidation: false,
            parcelaments: null,
            companyId: null,
            configManager: null,
            dialogSuccess: false,
            addNewCard: false,
            saveCreditCard: false,
            creditCards: [],
            selectedCardId: 0,
            taxOwner: TaxOwners.Company,
            availablePaymentMethods: null,
            availableDonationRecurrencePeriods: null,
            providerId: null,
            selectDollar: false,
            PaymentMethodProvider: PaymentMethodProvider,
            dolarPrice: 0,
            enableCoin: true,
            redirectToTicket: false,
            coins: [
                { id: "USD", name: "USD (Dólar)", prefix: "US$ " },
                { id: "BRL", name: "BRL (Real)", prefix: "R$ " },
            ],
            coinSelected: "BRL",
            addressDisable: {
                city: true,
                state: true,
            },
            loggedPerson: null,
            receiverParcelamentService: new ReceiverParcelamentService(),
            receiverParcelament: [],
            howMany: [{
                    quantidade: 0,
                    name: "Sem parcelamento",
                },
                {
                    quantidade: 2,
                    name: "2x",
                },
                {
                    quantidade: 3,
                    name: "3x",
                },
                {
                    quantidade: 4,
                    name: "4x",
                },
                {
                    quantidade: 5,
                    name: "5x",
                },
                {
                    quantidade: 6,
                    name: "6x",
                },
                {
                    quantidade: 7,
                    name: "7x",
                },
                {
                    quantidade: 8,
                    name: "8x",
                },
                {
                    quantidade: 9,
                    name: "9x",
                },
                {
                    quantidade: 10,
                    name: "10x",
                },
                {
                    quantidade: 11,
                    name: "11x",
                },
                {
                    quantidade: 12,
                    name: "12x",
                },
            ],
            GethowManyTicketParcelament: 1,
            minimumParcelamentValue: 0,
            parcelamentTotalValue: 0,
            parcelamentMethod: null,
            showPayRollInput: true,
            parcelamentsInputId: 0,
            yearsList: [],
            monthsList: []
        };
    },
    beforeRouteEnter: (to, from, next) => {
        let projectService = new ProjectService();
        if (to.query.projectId != null) {
            projectService
                .findProjectToAddToCart(to.query.projectId)
                .then(function (data) {
                    to.query.addedProject = data;
                    if (to.query.donationProductId != null) {
                        projectService
                            .findDonationProduct(to.query.donationProductId)
                            .then(function (data) {
                                to.query.addedDonationProduct = data;
                                next();
                            });
                    } else next();
                });
        } else {
            next();
        }
    },
    mounted() {
        const key = process.env.VUE_APP_GOOGLE_KEY_RECAPTCHA_V3;
        var script = document.createElement("script");
        this.mountYearList();
        this.mountMonthList();
        script.src = `https://www.google.com/recaptcha/api.js?render=${key}`;
        document.head.appendChild(script);
        this.setSuggestedValueFromSessionStorage();
        this.cartService = new CartService(null, pagarme);
        this.routes = Routes;

        this.daysOfMonth = [
            { id: 1, name: 1 },
            { id: 10, name: 10 },
            { id: 20, name: 20 },
        ];

        this.debitAccountBanks = [
            { code: "santander", name: "Santander" },
            { code: "itau", name: "Itaú" },
        ];

        if (this.$route.query.projectId && this.$route.query.addedProject) {
            var activeList = this.$route.query.addedProject.donationProductList.filter((item) => item.active);
            if (activeList.length == 0 || this.$route.query.addedDonationProduct != null) {
                this.addCartItem(
                    this.$route.query.addedProject,
                    this.$route.query.addedDonationProduct
                );
            } else {
                CommonHelper.swal(
                    "",
                    "Projeto selecionado tem produtos de doação, escolha um antes de prosseguir.",
                    "error"
                );
            }
        }

        this.cartService.getDolarPrice().then(
            function (data) {
                this.dolarPrice = parseFloat(data.bid);
                if (this.dolarPrice == 0) {
                    this.enableCoin = false;
                    this.coinSelected = "BRL";
                }
            }.bind(this)
        );

        this.loadPaymentMethodsWithCompany();
        //TODO Verificar metodos comentados

        this.campaignService.getCampaignLandingPageConfigBySubdomain(false).then(
            function (data) {
                this.campaignLandingPageConfig = data;
                this.taxOwner = this.campaignLandingPageConfig.campaign.taxOwner;
                this.enableCoin = this.campaignLandingPageConfig.campaign.allowUsdDonation;
                this.selectDollar = this.enableCoin
                if (
                    this.campaignLandingPageConfig.campaign
                        .modalConfirmationOnDonationActive
                ) {
                    this.personService.getLoggedPersonAddress().then((data) => {
                        this.loggedPerson = data;
                        if (
                            data.address.postalCode == "" ||
                            data.address.postalCode == null
                        )
                            this.$store.commit("SHOW_PERSON_INFO");
                    });
                }

                this.verifyURL().then(
                    this.onCartLoaded(this.cartLocal));

                this.receiverParcelamentService.listAll().then(res => {
                    if (res) { 
                        res.forEach(receiverParcelament => {
                            if (this.campaignLandingPageConfig.campaign.receiver == receiverParcelament.receiverId) {
                                this.receiverParcelament.push(receiverParcelament);
                            }
                        });
                    }
                })
                if (
                    this.campaignLandingPageConfig.campaign &&
                    this.campaignLandingPageConfig.campaign.id == 9
                ) {
                    if (typeof Init === "function") {
                        Init(
                            "?botID=30501&appID=",
                            600,
                            600,
                            "https://dvgpba5hywmpo.cloudfront.net/media/image/TYsW63oOYPxbyocUIdDliFOrh",
                            "bubble",
                            "#00AFF0",
                            90,
                            90,
                            62.99999999999999,
                            "",
                            "1",
                            "#FFFFFF",
                            0
                        );
                    }
                } else if (
                    this.campaignLandingPageConfig.campaign &&
                    this.campaignLandingPageConfig.campaign.id == 10
                ) {
                    if (typeof Init === "function") {
                        Init(
                            "?botID=31343&appID=webchat",
                            600,
                            600,
                            "https://dvgpba5hywmpo.cloudfront.net/media/image/3VORB6g4yeFLOb05BNGwG9fly",
                            "bubble",
                            "#000000",
                            90,
                            90,
                            62.99999999999999,
                            "",
                            "1",
                            "#FFFFFF",
                            0
                        );
                    }
                }
            }.bind(this)
        );
    },
    updated(){
        if (this.campaignLandingPageConfig.campaign && !this.campaignLandingPageConfig.campaign.donateToMoreThanOneProduct && this.cartLocal.cartItems.length > 1 ) {
            this.verifyURL().then(
                this.onCartLoaded(this.cartLocal));
        }
    },
    methods: {
        ...mapActions([
            "addToCart",
            "removeToCart",
            "clearCart",
            "updateCart",
            "setCartSuccess",
        ]),
        loadPaymentMethodsWithCompany(){
            this.cartService.loadPaymentMethodsWithCompany().then((data) => {
                this.hasCompany = data.hasCompany
                this.companyName = data.companyName
                this.companyCNPJ = data.companyCnpj
                this.pixEnabled = data.pix
                this.creditCardEnabled = data.creditCard
                this.ticketEnabled = data.ticket
                this.payrollDeductionEnabled = data.payrollDeduction
                this.debitAccountEnabled = data.debitAccount
                this.parcelaments = data.payrollParcelaments;
                this.hasPayRollParcelament = data.hasPayRollParcelament
                if (!this.hasPayRollParcelament && this.cart) {
                    this.cart.donationRecurrencePeriodId = DonationRecurrencePeriod.Single;
                }
            })
        },
        mountYearList(){
            const startYear =  new Date().getFullYear();
            const endYear = startYear + 15;
            for (let i = startYear; i <= endYear; i++) {
                this.yearsList.push(i);
            }
        },
        mountMonthList(){
            for (var i = 1; i <= 12; i++) {
                this.monthsList.push(String(i).padStart(2, '0'));
            }
        },
        keyCreditCardParcelamentChange(event){            
            this.resetCreditCardParcelament();
            this.keyCreditCardParcelament = event;
        },
        reloadPaymentMethods(){
            this.campaignService.getCampaignLandingPageConfigBySubdomain(false).then((result) =>{    
                if(result)
                    this.campaignLandingPageConfig.campaign.paymentMethodList = result.campaign.paymentMethodList;
            })
        },
        setParcelamentMethod(payment){
            if (this.campaignLandingPageConfig.campaign.campaignPaymentMethodParcelamentList 
            && this.campaignLandingPageConfig.campaign.campaignPaymentMethodParcelamentList.find(x => x.paymentMethodId == payment)) {
                this.parcelamentMethod = this.campaignLandingPageConfig.campaign.campaignPaymentMethodParcelamentList.find(x => x.paymentMethodId == payment);
            }
        },
        verifyParcelamentPaymentMethod(){
            var campaignPaymentMethodParcelamentList = this.campaignLandingPageConfig.campaign.campaignPaymentMethodParcelamentList;
            if (campaignPaymentMethodParcelamentList && campaignPaymentMethodParcelamentList.length > 0) {
                for (let index = 0; index < campaignPaymentMethodParcelamentList.length; index++) {
                const element = campaignPaymentMethodParcelamentList[index];
                if (element.paymentMethodId == this.PaymentMethod.CreditCard 
                && this.cart.paymentMethodId == this.PaymentMethod.CreditCard  
                && this.cart.donationRecurrencePeriodId == 1) {
                    return element.parcelament > 1;
                }
                if (element.paymentMethodId == this.PaymentMethod.Ticket 
                && this.cart.paymentMethodId == this.PaymentMethod.Ticket) {
                    if (this.cart.donationRecurrencePeriodId == 1) {
                        return element.parcelament > 1;
                    }
                    return false;
                }
                if (element.paymentMethodId == this.PaymentMethod.Pix 
                && this.cart.paymentMethodId == this.PaymentMethod.Pix) 
                {
                    if (this.cart.donationRecurrencePeriodId == 1) {
                        return element.parcelament > 1;
                    }
                    return false;
                }
                }
            }

            return this.campaignLandingPageConfig.campaign.creditCardParcelament > 2 && (this.cart.paymentMethodId == this.PaymentMethod.Ticket || this.cart.paymentMethodId == this.PaymentMethod.Pix);
        },
        getPixTaxParcelamentValue(parcelamentNumber){
            var parcelamentValue = this.cartTotalPrice / parcelamentNumber;
            var tax = parcelamentValue / ((100 - this.configManager.taxPix) / 100) - parcelamentValue;
            tax = tax <= 150 ? tax : 150; 
            return tax;
        },
        getParcelamentMessageText(){
            var currentYear = new Date().getFullYear();
            return `Somente os valores pagos até o dia 26/12/${currentYear} poderão ser deduzidos em seu Imposto de Renda PF ${currentYear + 1} (exercício ${currentYear}). 
            Caso queira que todo o valor doado seja deduzido no IR do próximo ano, por favor, efetue todos os pagamentos até essa data.
            As informações referentes aos valores que devem ser considerados para abatimento do IRPF ${currentYear + 1} serão enviadas por e-mail, no Informe de Doações.
            `;
        },
        showParcelamentMessage(parcelament){
            var month = new Date().getMonth() + 1;
            var totalMonth = month + parseInt(parcelament);            
            if (totalMonth > 12 && this.campaignLandingPageConfig.campaign.campaignTypeId == CampaignTypes.Incentivada) {
                this.$refs.parcelamentMessage.open();    
            }
        },
        async recaptcha(){
            let key = process.env.VUE_APP_GOOGLE_KEY_RECAPTCHA_V3;
            return await grecaptcha.execute(key, { action: "submit" });
        },
        needAnAdrress() {
            if (
                this.campaignLandingPageConfig.campaign
                    .modalConfirmationOnDonationActive
                &&
                this.campaignLandingPageConfig.campaign.
                    campaignInputInformation.modalConfirmationInformAddress
            ) {
                if (!this.loggedPerson || !this.loggedPerson.address || !this.loggedPerson.address.postalCode) {
                    this.personService.getLoggedPersonAddress().then((data) => {
                        this.loggedPerson = data;
                        if (
                            data.address  == null ||
                            data.address.postalCode == "" ||
                            data.address.postalCode == null
                        ) {
                            this.CommonHelper.swal(
                                "Você precisa preencher o endereço para continuar, caso o campo de endereço não apareça, abra a página em guia anônima!"
                            );
                            this.$store.commit("SHOW_PERSON_INFO_NOW");
                        }
                    });
                    return true;
                }
            }
            return false;
        },
        myClickEvent() {
            const elem = this.$refs.myBtn;
            elem.click();
        },
        gotoCampaignLP() {
            this.$router.push({ name: Routes.app.CampaignLandingPage });
        },
        gotoUserDashboard() {
            this.$router.push({ name: Routes.app.DashboardPF });
        },
        setSuggestedValueFromSessionStorage() {
            this.simulationValue = this.getSuggestedValueFromSessionStorage();
        },
        updateSimulationValue(data) {
            this.simulationValue = data;
        },
        changeSelectedCoin(value) {

            //if (this.coinSelected == "USD") {
            if (!value) {
                this.coinSelected = "BRL";
            } else {
                this.coinSelected = "USD";
            }
        },
        generateTicket() {
            if (!this.isValid()) return;
            if (this.cartTotalPrice < this.minimumPeriodDonationValue) {
                this.CommonHelper.swal(
                    "O valor mínimo desta campanha é R$" + this.minimumPeriodDonationValue
                );
                return;
            }
            if (this.cart.donationRecurrencePeriodId > 1) {
                this.resetCreditCardParcelament();
            }
            if (
                this.simulationValue - this.cartTotalPrice > 0 &&
                !this.confirmAlreadyOpen
            ) {
                this.openConfirmationDonation("generateTicket");
                return;
            }
            if (this.terms) {
                this.recaptcha().then((token) => { 
                    this.cart.checkoutRequest.tokenRecaptcha = token;               
                    this.$store.commit("SET_TICKET_CART", this.cart);

                    this.cart.totalValue = this.cartTotalPrice;
                    this.finishedCart = this.cart;
                    this.redirectToTicket = true;
                    this.redirectToCongratsPage();

                    this.clearCart();
                })
            } else {
                CommonHelper.swal(
                    "",
                    "Você precisa aceitar os termos de Anuência para concluir a compra!",
                    "error"
                );
            }
        },
        pixCheckout() {

            if (this.needAnAdrress()) return;
            if (!this.isValid()) return;
            if (this.cartTotalPrice < this.minimumPeriodDonationValue) {
                this.CommonHelper.swal(
                    "O valor mínimo desta campanha é R$" + this.minimumPeriodDonationValue
                );
                return;
            }
            if (
                this.simulationValue - this.cartTotalPrice > 0 &&
                !this.confirmAlreadyOpen
            ) {
                this.openConfirmationDonation("pixCheckout");
                return;
            }
            if (this.terms) {
                this.cart.totalValue = this.cartTotalPrice;
                this.recaptcha().then((token) =>{
                    this.cart.checkoutRequest.tokenRecaptcha = token;
                    this.cartService.checkout(this.cart).then((cartUpdated) => {
                    this.$refs.modalPix.open(cartUpdated);
                }).then(this.sendPurshase(this.cart));
                })                
            } else {
                CommonHelper.swal(
                    "",
                    "Você precisa aceitar os termos de Anuência para concluir a compra!",
                    "error"
                );
            }
        },
        finishPix() {
            this.$store.commit("SET_TICKET_CART", this.cart);
            this.cart.totalValue = this.cartTotalPrice;
            this.finishedCart = this.cart;
            this.redirectToTicket = false;
            this.clearCart();
            this.redirectToCongratsPage();
        },
        redirectToCongratsPage() {
            this.dialogSuccess = true;
            this.setCartSuccess({
                cartId: this.finishedCart.id,
                totalValue: this.finishedCartTotalValue,
                campaign: this.campaignLandingPageConfig.campaign,
                paymentMethodId: this.finishedPaymentMethodId,
            });

            if (this.redirectToTicket) {
                let routeData = this.$router.resolve({
                    name: Routes.web.GenerateTicket,
                });
                window.open(routeData.href, "_blank");
            }
            this.clearCart();
            if(isNaN(this.finishedCartId))
                this.$router.push({
                    name: Routes.app.Congrats,
                    query: { id: this.finishedCartId },
                });
            else
                this.$router.push({
                    name: Routes.app.Congrats,
                    query: { id: this.finishedCart.id },
                });
        },
        getSuggestedValueFromSessionStorage() {
            if (sessionStorage.getItem("result"))
                return sessionStorage.getItem("result");
            return 0;
        },
        openConsentTerm(paymentMethod) {
            if (
                paymentMethod == PaymentMethod.CreditCard &&
                !this.isPaymentSimplified
            ) {
                if (!this.isCreditCardInformationValid()) return;
            }
            if (this.isValid()) {
                this.$refs.consertTermComponent.open();
            }
        },
        openSimulationAlertCart() {
            this.$refs.confirmationComponent.open();
        },
        openConfirmationDonation(action) {
            this.confirmAlreadyOpen = true;
            this.actionCheckout = action;
            this.textSimulationConfirm =
                "Você ainda pode doar R$ " +
                this.formatterHelper.formatMoney(
                    this.simulationValue - this.cartTotalPrice
                ) +
                " abatendo do seu Imposto de Renda";
            this.$refs.confirmWithValueSimulation.open();
        },
        openRemoveCartItem(cartitem) {
            this.selectedCartItem = cartitem;
            this.$refs.removeCartItemAlert.open();
        },
        init(cart) {                    
            cart.checkoutRequest = new CheckoutRequest();
            cart.checkoutRequest.creditCardInfo.billingAddress.country = "BRA";
            return cart;
        },
        birthDateValidation() {
            if (!this.birthDate) {
                return false;
            }
            var current = parse(this.birthDate);
            var today = new Date();
            today.setHours(0, 0, 0, 0);
            return current < today;
        },
        cvvValidation() {
            if (
                !this.cart ||
                !this.cart.checkoutRequest ||
                !this.cart.checkoutRequest.creditCardInfo ||
                !this.cart.checkoutRequest.creditCardInfo.cvv
            ) {
                return false;
            }
            return (
                this.cart.checkoutRequest.creditCardInfo.cvv.length == 3 ||
                this.cart.checkoutRequest.creditCardInfo.cvv.length == 4
            );
        },
        expirationDateValidation() {
            if (
                !this.cart ||
                !this.cart.checkoutRequest ||
                !this.cart.checkoutRequest.creditCardInfo ||
                !this.cart.checkoutRequest.creditCardInfo.expirationMonth ||
                !this.cart.checkoutRequest.creditCardInfo.expirationYear
            )
                return false;
            let currentYear = this.$moment().year();
            let currentMonth = this.$moment().month();
            let expirationMonth = this.cart.checkoutRequest.creditCardInfo
                .expirationMonth;
            let expirationYear = this.cart.checkoutRequest.creditCardInfo
                .expirationYear;
            if (expirationMonth > 12) return false;
            if (
                expirationYear > currentYear ||
                (expirationYear == currentYear && expirationMonth >= currentMonth)
            ) {
                return true;
            }
            return false;
        },
        validateCardName() {
            if (
                this.cart &&
                this.cart.checkoutRequest &&
                this.cart.checkoutRequest.creditCardInfo &&
                this.cart.checkoutRequest.creditCardInfo.holder.name
            ){
                const cardFullName = this.cart.checkoutRequest.creditCardInfo.holder.name.trim();
                const nameParts = cardFullName.split(/\s+/);
                if (nameParts.length >= 2) 
                    return true;
                return false;   
            }         
        },
        creditCardNumberValidation() {
            if (
                this.cart &&
                this.cart.checkoutRequest &&
                this.cart.checkoutRequest.creditCardInfo &&
                this.cart.checkoutRequest.creditCardInfo.cardNumber
            ) {
                return (
                    this.cart.checkoutRequest.creditCardInfo.cardNumber.length >= 15 &&
                    this.cart.checkoutRequest.creditCardInfo.cardNumber.length <= 16
                );
            }
        },
        nameToUpperCase() {
            if (
                this.cart &&
                this.cart.checkoutRequest &&
                this.cart.checkoutRequest.creditCardInfo &&
                this.cart.checkoutRequest.creditCardInfo.holder &&
                this.cart.checkoutRequest.creditCardInfo.holder.name
            )
                this.cart.checkoutRequest.creditCardInfo.holder.name = this.cart.checkoutRequest.creditCardInfo.holder.name.toUpperCase();
        },
        listAllStateCallback(data) {
            this.stateItems = data;
        },
        searchCEP(type) {
            if (
                this.cart &&
                this.cart.checkoutRequest &&
                this.cart.checkoutRequest[type] &&
                this.cart.checkoutRequest[type].billingAddress &&
                this.cart.checkoutRequest[type].billingAddress.postalCode &&
                this.cart.checkoutRequest[type].billingAddress.postalCode.length == 8
            ) {
                this.$store.commit("SET_LOADER", true);
                this.locationService
                    .searchCEP(this.cart.checkoutRequest[type].billingAddress.postalCode)
                    .then((obj) => {
                        this.searchCEPCallback(type, obj);
                    });
            }
        },
        searchCEPCallback(type, obj) {
            if (obj && !obj.erro) {
                this.cart.checkoutRequest[type].billingAddress.street = obj.street;
                this.cart.checkoutRequest[type].billingAddress.district = obj.neighborhood;
                this.cart.checkoutRequest[type].billingAddress.city = obj.city;
                this.cart.checkoutRequest[type].billingAddress.state = obj.state;
                this.cepValidation = true;
                this.addressDisable.city = true;
                this.addressDisable.state = true;
            } else {
                this.cart.checkoutRequest[type].billingAddress.street = "";
                this.cart.checkoutRequest[type].billingAddress.district = "";
                this.cart.checkoutRequest[type].billingAddress.city = "";
                this.cart.checkoutRequest[type].billingAddress.state = "";
                this.cepValidation = false;
                this.addressDisable.city = false;
                this.addressDisable.state = false;
            }
            this.$store.commit("SET_LOADER", false);
        },
        getPhoneNumberAndAreaCode(type) {
            if (
                this.cart &&
                this.cart.checkoutRequest &&
                this.cart.checkoutRequest[type] &&
                this.cart.checkoutRequest[type].holder &&
                this.cart.checkoutRequest[type].holder.phone &&
                this.cart.checkoutRequest[type].holder.phone.areaCode &&
                this.cart.checkoutRequest[type].holder.phone.number
            ) {
                return (
                    this.cart.checkoutRequest[type].holder.phone.areaCode.toString() +
                    this.cart.checkoutRequest[type].holder.phone.number.toString()
                );
            }
            return "";
        },
        updateExpirationPhoneNumberAndAreaCode(event, type) {
            if (event && event.length >= 10) {
                this.cart.checkoutRequest[type].holder.phone.areaCode = event.substring(
                    0,
                    2
                );
                this.cart.checkoutRequest[type].holder.phone.number = event.substring(
                    2
                );
            } else {
                this.cart.checkoutRequest[type].holder.phone.areaCode = "";
                this.cart.checkoutRequest[type].holder.phone.number = "";
            }
        },
        getExpirationMonthAndYear() {
            if (
                this.cart &&
                this.cart.checkoutRequest &&
                this.cart.checkoutRequest.creditCardInfo &&
                this.cart.checkoutRequest.creditCardInfo.expirationMonth &&
                this.cart.checkoutRequest.creditCardInfo.expirationYear
            ) {
                return (
                    this.cart.checkoutRequest.creditCardInfo.expirationMonth.toString() +
                    this.cart.checkoutRequest.creditCardInfo.expirationYear.toString()
                );
            }
            return "";
        },
        updateExpirationMonthAndYear(event) {
            if (event && event.length == 6) {
                this.cart.checkoutRequest.creditCardInfo.expirationMonth = event.substring(
                    0,
                    2
                );
                this.cart.checkoutRequest.creditCardInfo.expirationYear = event.substring(
                    2,
                    6
                );
            } else {
                this.cart.checkoutRequest.creditCardInfo.expirationMonth = "";
                this.cart.checkoutRequest.creditCardInfo.expirationYear = "";
            }
        },
        isCreditCardInformationValid() {
            let result = this.isValid();
            if (result) {
                if (
                    !CommonHelper.validateCPF(
                        this.cart.checkoutRequest.creditCardInfo.holder.CPF
                    ) && !CommonHelper.validateCNPJ(this.cart.checkoutRequest.creditCardInfo.holder.CPF)
                ) {
                    CommonHelper.swal(`${this.campaignLandingPageConfig.campaign.permitCnpjDonation ? 'CPF' : 'CPF/CNPJ'} inválido`);
                    return false;
                }
            }
            return result;
        },
        isDebitAccountInformationValid() {
            let result = this.isValid();
            return result;
        },
        acceptConsentTerm() {
            this.terms = true;
        },
        toggleSimulation() {
            this.landingPageOptions.simulationDrawer = !this.landingPageOptions
                .simulationDrawer;
        },
        callPaymentMethod() {
            if (this.needAnAdrress()) return;
            if (!this.isValid()) return;
            switch (this.actionCheckout) {
                case "checkout":
                    this.checkout();
                    break;
                case "creditCardCheckout":
                    this.creditCardCheckout();
                    break;
                case "generateTicket":
                    this.generateTicket();
                    break;
                case "debitAccountCheckout":
                    this.debitAccountCheckout();
                case "pixCheckout":
                    this.pixCheckout();
                    break;
            }
        },
        checkout() {
            console.log("PASSOU")
            if (this.needAnAdrress()) return;
            if (!this.isValid()) return;
            if (this.simulationValue - this.cartTotalPrice > 0 && !this.confirmAlreadyOpen) {
                this.openConfirmationDonation("checkout");
                return;
            }
            if (!this.cart.paymentMethodId) this.cart.paymentMethodId = this.PaymentMethod.PayrollDeduction;
            if (this.isValid()) {
                if (!this.terms) {
                    this.CommonHelper.swal(
                        "Você precisa aceitar os termos de Anuência para concluir a compra!"
                    );
                    return;
                }
                this.recaptcha().then((token) => {
                    this.cart.checkoutRequest.tokenRecaptcha = token
                    this.cartService.checkout(this.cart)
                    .then(this.onCheckout)
                })                
            }            
        },
        sendPurshase(cart){
            window.dataLayer = window.dataLayer || [];
            const methods = {
                2: "Cartão de crédito",
                6: "Pix",
                3: "Boleto",
                4: "Débito em conta"
            }      
            const purshase = {
                event: 'purchase',
                ecommerce: {
                transaction_id: cart.id,
                value: cart.cartItems.reduce((accumulator, cartItem) => accumulator + cartItem.itemValue, 0),
                currency: 'BRL',
                items: cart.cartItems.map(x => {return {
                    item_name: methods[cart.paymentMethodId],
                    item_id: cart.donationRecurrencePeriodId,
                    price: x.itemValue,
                    quantity: 1
                }})
                },       
            }      
            console.log("purshase", purshase)
            window.dataLayer.push(purshase)
        },
        creditCardCheckout() {
            if (this.needAnAdrress()) return;
            if (!this.isValid()) return;
            if (!this.addNewCard && this.selectedCardId == 0) {
                this.CommonHelper.swal(
                    "Você precisa selecionar um cartão para concluir a compra!"
                );
                return;
            }
            this.cart.paymentMethodProviderId = this.campaignLandingPageConfig.campaign.campaignPaymentMethodProvider.creditCardPaymentMethodProviderId;

            if (
                this.simulationValue - this.cartTotalPrice > 0 &&
                !this.confirmAlreadyOpen
            ) {
                this.openConfirmationDonation("creditCardCheckout");
                return;
            }

            if (!this.isPaymentSimplified) {
                if (!this.isCreditCardInformationValid()) return;
                this.cart.checkoutRequest.creditCardInfo.holder.birthDate = this.$moment(
                    this.birthDate,
                    "YYYY-MM-DD"
                ).format("DD/MM/YYYY");
            }

            if (this.cartTotalPrice < this.minimumPeriodDonationValue) {
                this.CommonHelper.swal(
                    "O valor mínimo desta campanha é R$" + this.minimumPeriodDonationValue
                );
                return;
            }

            if (!this.terms) {
                this.CommonHelper.swal(
                    "Você precisa aceitar os termos de Anuência para concluir a compra!"
                );
                return;
            }

            if (!this.addNewCard) {
                this.cart.checkoutRequest.creditCardInfo.creditCardId = this.selectedCardId;
            }
            this.CommonHelper.showLoader();
            this.recaptcha().then((token) => {
                this.cart.checkoutRequest.tokenRecaptcha = token;
                if (this.saveCreditCard) {
                    this.creditCard = new CreditCard();

                    this.creditCard.cardNumber = this.cart.checkoutRequest.creditCardInfo.cardNumber;
                    this.creditCard.cardName = this.cart.checkoutRequest.creditCardInfo.holder.name;
                    this.creditCard.expirationDate =
                        this.cart.checkoutRequest.creditCardInfo.expirationMonth.toString() +
                        this.cart.checkoutRequest.creditCardInfo.expirationYear.toString();
                    this.creditCard.CVV = this.cart.checkoutRequest.creditCardInfo.cvv;

                    this.cartService.createCreditCard(this.creditCard).then(() => {
                        this.getCreditCards().then(() => {
                            let savedCreditCard = this.creditCards.find((x) =>
                                this.cart.checkoutRequest.creditCardInfo.cardNumber.includes(
                                    x.lastDigits
                                )
                            );
                            this.cart.checkoutRequest.creditCardInfo.creditCardId =
                                savedCreditCard.id;

                            this.cartService
                                .creditCardCheckout(this.cart)
                                .then(this.setupCreditCardStatus)
                                .then(this.onCheckout)
                                .catch((err) => {
                                    if (err.errorMessage)
                                        CommonHelper.swal(err.errorMessage);
                                    else
                                        CommonHelper.swal(err);
                                    console.log(err);
                                })
                        }).catch((e) => console.log(e));
                    });
                } else {
                    this.cartService
                        .creditCardCheckout(this.cart)
                        .then(this.setupCreditCardStatus)
                        .then(this.onCheckout)
                        .catch((err) => {
                            if (err.errorMessage)
                                CommonHelper.swal(err.errorMessage);
                            else
                                CommonHelper.swal(err);
                            console.log(err);
                        });
                }
            }).finally(() => {
                this.CommonHelper.hideLoader();
            })
        },
        setupCreditCardStatus(cart) {
            if (cart.statusId == CartStatus.PaymentError)
                throw "Não foi possível efetuar a operação, verifique junto a seu banco ou operadora de cartão se há algum problema.";
            return cart;
        },
        debitAccountCheckout() {
            if (this.needAnAdrress()) return;
            if (!this.isValid()) return;
            if (
                this.simulationValue - this.cartTotalPrice > 0 &&
                !this.confirmAlreadyOpen
            ) {
                this.openConfirmationDonation("debitAccountCheckout");
                return;
            }
            if (!this.isDebitAccountInformationValid()) return;
            if (!this.terms) {
                this.CommonHelper.swal(
                    "Você precisa aceitar os termos de Anuência para concluir a compra!"
                );
                return;
            }
            this.cart.checkoutRequest.debitAccountInfo.billingAddress.country = "br";
            this.recaptcha().then((token) => {
                this.cart.checkoutRequest.tokenRecaptcha = token;
                this.cartService
                .debitAccountCheckout(this.cart)
                .then(this.onCheckout, (data) => {
                    if (data && data.errorMessage) {
                        this.rollbackRemoveItem(payload.item, data.errorMessage);
                    }
                });
            })            
        },
        addCartItem(project, donationProduct = null) {
            var cartItem = {
                project: project,
                donationProduct: donationProduct,
            };
            this.addToCart(cartItem);
        },
        removeCartItem(cartItem) {
            this.removeToCart(cartItem);
        },
        onCheckout(cart) {
            cart.totalValue = this.cartTotalPrice;
            this.finishedCart = cart;
            this.sendPurshase(cart)
            this.redirectToCongratsPage();
            this.clearCart();
        },
        periodRecurrenceSelectedOnCreate() {
            if (this.containsPaymentMethodAndFitsTheChosenRecurrencePeriod(PaymentMethod.CreditCard))
                return PaymentMethod.CreditCard;
            else if (this.containsPaymentMethodAndFitsTheChosenRecurrencePeriod(PaymentMethod.Ticket))
                return PaymentMethod.Ticket;
            else if (this.containsPaymentMethodAndFitsTheChosenRecurrencePeriod(PaymentMethod.PayrollDeduction))
                return PaymentMethod.PayrollDeduction;
            else if (this.containsPaymentMethodAndFitsTheChosenRecurrencePeriod(PaymentMethod.AccountDebit))
                return PaymentMethod.AccountDebit;
            else if (this.containsPaymentMethodAndFitsTheChosenRecurrencePeriod(PaymentMethod.Pix))
                return PaymentMethod.Pix;
        },
        onCartLoaded(cart) {
            this.cart = cart;
            this.cart.paymentMethodId = this.periodRecurrenceSelectedOnCreate();
            this.changeProvidersAndTax(this.cart.paymentMethodId);
            this.getCreditCards()
            if (this.cart.cartItems < 1)
                this.$router.push({ name: Routes.app.CampaignLandingPage });

            
            if (this.campaignLandingPageConfig.campaign && !this.campaignLandingPageConfig.campaign.donateToMoreThanOneProduct && cart.cartItems.length > 1) {
                var cartsToRemove = this.cart.cartItems.length - 1;

                for (let index = 0; index < cartsToRemove; index++) {
                    const remove = this.cart.cartItems[0];
                    this.removeToCart(remove);
                    this.cart.cartItems.shift();
                }
            }

            // Set valor minimo de doação da campanha em cada projeto
            // verifica se existe uma simulacao e preenche o valor sugerido
            if (cart.cartItems.length == 1) {
                cart.cartItems.forEach((cartItem) => {
                    cartItem.itemValue = this.getSuggestedValueFromSessionStorage(); //cart.campaign.minimumDonationValue;
                });
            } else {
                cart.cartItems.forEach((cartItem) => {
                    cartItem.itemValue = 0; //cart.campaign.minimumDonationValue;
                });
            }

            this.cart.paymentMethodId = (this.cart.paymentMethodId || "").toString();
            this.cart.paymentMethodProviderId = (
                this.cart.paymentMethodProviderId || ""
            ).toString();
            this.$set(
                this.cart,
                "creditCardCartDetails",
                cart.creditCardCartDetails || new CreditCardCartDetails()
            );
            this.$set(
                this.landingPageOptions,
                "logo",
                this.campaignLandingPageConfig.campaign.file
            );
            //this.configManagerService.findConfigManagerByPaymentMethodProviderId(this.callbackConfigManager, this.campaignLandingPageConfig.campaign.paymentMethodProviderSelected);

            this.hasProjectIncentived =
                this.cart.cartItems
                    .map(function (el) {
                        return el.project.incentiveLawId;
                    })
                    .filter(function (r) {
                        return r != null;
                    }).length > 0;

            if (!this.cart.creditCardParcelament) {
                this.cart.creditCardParcelament = 0;
            }
            if (!this.cart.checkoutRequest.ticketInfo) {
                this.cart.checkoutRequest.ticketInfo = {};
            }
            if (!this.cart.checkoutRequest.pixInfo) {
                this.cart.checkoutRequest.pixInfo = {};
            }
        },
        containsPaymentMethodAndFitsTheChosenRecurrencePeriod(paymentMethod) {
            switch (paymentMethod) {
                case PaymentMethod.Pix:
                    return this.pixEnabled
                case PaymentMethod.CreditCard:
                    return this.creditCardEnabled
                case PaymentMethod.PayrollDeduction:
                    return this.payrollDeductionEnabled
                case PaymentMethod.AccountDebit:
                    return this.debitAccountEnabled
                case PaymentMethod.Ticket:
                    return this.ticketEnabled
            }
        },
        fitsTheChosenRecurrencePeriod(paymentMethod) {
            let chosenRecurrencePeriod = parseInt(
                this.cart.donationRecurrencePeriodId
            );

            switch (chosenRecurrencePeriod) {
                //Todos os tipos de pagamento existentes suportam recorrência única
                case DonationRecurrencePeriod.Single:
                    return true;
                default:
                    return (
                        paymentMethod === PaymentMethod.CreditCard ||
                        paymentMethod === PaymentMethod.PayrollDeduction
                    );
            }
        },
        addMoreProjects() {
            this.$router.push({
                name: Routes.app.CampaignLandingPage,
                query: { open: "projetos" },
            });
        },
        getSimulationResult() {
            var result = sessionStorage.result ? sessionStorage.result : 0;
            return result;
        },
        callbackConfigManager(data) {
            this.configManager = data;
        },
        cartTaxMethodPayment() {
            let totalPrice = this.cartTotalPrice;
            let tax = 0;

            if (totalPrice == 0) {
                return 0;
            }

            if (this.taxOwner == this.taxOwners.Company) {
                this.cart.taxValue = 0;
                return 0;
            }

            if (this.cart.paymentMethodId == PaymentMethod.Ticket) {
                tax = this.configManager.taxBoleto;
            } else if (
                this.cart.paymentMethodId == PaymentMethod.CreditCard &&
                (this.cart.donationRecurrencePeriodId == null ||
                    this.cart.donationRecurrencePeriodId ==
                    DonationRecurrencePeriod.Single)
            ) {
                var parcelament = (!this.cart.creditCardParcelament || this.cart.creditCardParcelament == 0) ? 1 : this.cart.creditCardParcelament;
                tax =
                    totalPrice /
                    ((100 - this.receiverParcelament.find(x => x.parcelament == parcelament).singleTransactionValue) / 100) -
                    totalPrice;
                
            } else if (
                this.cart.paymentMethodId == PaymentMethod.CreditCard &&
                this.cart.donationRecurrencePeriodId > DonationRecurrencePeriod.Single
            ) {
                tax =
                    totalPrice /
                    ((100 - this.receiverParcelament.find(x => x.parcelament == 1).singleTransactionValue) / 100) -
                    totalPrice;
            } else if (
                this.cart.paymentMethodId == PaymentMethod.AccountDebit &&
                (this.cart.donationRecurrencePeriodId == null ||
                    this.cart.donationRecurrencePeriodId ==
                    DonationRecurrencePeriod.Single)
            ) {
                tax =
                    totalPrice /
                    ((100 - this.configManager.taxAccountDebit) / 100) -
                    totalPrice;
            } else if (
                this.cart.paymentMethodId == PaymentMethod.AccountDebit &&
                this.cart.donationRecurrencePeriodId > DonationRecurrencePeriod.Single
            ) {
                tax =
                    totalPrice /
                    ((100 - this.configManager.taxRecurrentAccountDebit) / 100) -
                    totalPrice;
            }
            else if (this.cart.paymentMethodId == PaymentMethod.Pix) {
                tax =
                    totalPrice /
                    ((100 - this.configManager.taxPix) / 100) -
                    totalPrice;
                tax = tax <= 150 ? tax : 150;
                if (this.cart.creditCardParcelament > 1) {
                    tax = this.getPixTaxParcelamentValue(this.cart.creditCardParcelament)
                    this.cart.taxValue = tax * this.cart.creditCardParcelament
                    return tax;
                }
            }

            this.cart.taxValue = tax;

            return tax;
        },
        abrirLogin() {
            if (!this.logged) {
                this.$store.commit("SHOW_LOGIN");
            }
        },
        onClickTab(paymentMethod) {
            this.abrirLogin();
            this.changeProvidersAndTax(paymentMethod);
        },
        changeProvidersAndTax(paymentMethod) {
            this.resetCreditCardParcelament();
            this.setParcelamentMethod(paymentMethod);
            switch (paymentMethod) {
                case PaymentMethod.CreditCard:
                    this.providerId = this.campaignLandingPageConfig.campaign.campaignPaymentMethodProvider.creditCardPaymentMethodProviderId;
                    this.configManager = this.campaignLandingPageConfig.campaign.campaignPaymentMethodProvider.creditCardPaymentMethodProvider.configManager;
                    this.taxOwner = this.campaignLandingPageConfig.campaign.taxOwnerCreditCard;
                    break;
                case PaymentMethod.Ticket:
                    this.providerId = this.campaignLandingPageConfig.campaign.campaignPaymentMethodProvider.ticketPaymentMethodProviderId;
                    this.configManager = this.campaignLandingPageConfig.campaign.campaignPaymentMethodProvider.ticketPaymentMethodProvider.configManager;
                    this.taxOwner = this.campaignLandingPageConfig.campaign.taxOwnerTicket;
                    break;
                case PaymentMethod.AccountDebit:
                    this.providerId = this.campaignLandingPageConfig.campaign.campaignPaymentMethodProvider.accountDebitPaymentMethodProviderId;
                    this.configManager = this.campaignLandingPageConfig.campaign.campaignPaymentMethodProvider.accountDebitPaymentMethodProvider.configManager;
                    break;
                case PaymentMethod.Pix:
                    this.providerId = this.campaignLandingPageConfig.campaign.campaignPaymentMethodProvider.pixPaymentMethodProviderId;
                    this.configManager = this.campaignLandingPageConfig.campaign.campaignPaymentMethodProvider.pixPaymentMethodProvider.configManager;
                    this.taxOwner = this.campaignLandingPageConfig.campaign.taxOwnerPix;
                    break;
                case PaymentMethod.PayrollDeduction:
                    break;
                default:
                    break;
            }
        },
        resetCreditCardParcelament(){
            this.cart.creditCardParcelament = 1;
            this.GethowManyTicketParcelament += 1;
        },
        updateItemsValue(current, old) {
            for (var i = 0; i < current.length; i++) {
                var item = old.filter((e) =>
                    current[i].donationProduct
                        ? e.donationProduct &&
                        e.donationProduct.id == current[i].donationProduct.id
                        : current[i].project.id == e.project.id
                );
                if (item && item.length > 0) {
                    current[i].itemValue = item[0].itemValue ? item[0].itemValue : 0;
                    if (
                        item[0].donationProduct &&
                        item[0].donationProduct.donationProductQuestionList &&
                        item[0].donationProduct.donationProductQuestionList.length > 0
                    ) {
                        for (
                            var j = 0;
                            j < item[0].donationProduct.donationProductQuestionList.length;
                            j++
                        ) {
                            current[i].donationProduct.donationProductQuestionList[j].answer =
                                item[0].donationProduct.donationProductQuestionList[j].answer;
                        }
                    }
                }
            }
            return current;
        },
        buildProfileURL() {
            let nextRoute = this.$router.resolve({
                name: Routes.web.Profile,
            });
            let hostWithSubdomain = window.location.host;

            return "https://" + hostWithSubdomain + nextRoute.href;
        },
        maximumPurchase(cartItem) {
            var donationProduct = cartItem.donationProduct;
            var project = cartItem.project;

            let min = this.calculateMinValueForPurchase(
                project.maxItemQuantityPerCPF,
                donationProduct.ticketsAvailableForSale
            );

            return donationProduct.openTickets ? false : donationProduct.currentQuantity >= min;
        },
        minimumPurchase(donationProduct) {
            return donationProduct.currentQuantity <= 1;
        },
        changeCurrentQuantity(cartItem) {
            var donationProduct = cartItem.donationProduct;
            var project = cartItem.project;

            if (donationProduct.currentQuantity)
                donationProduct.currentQuantity = parseInt(
                    donationProduct.currentQuantity
                );

            let min = this.calculateMinValueForPurchase(
                project.maxItemQuantityPerCPF,
                donationProduct.ticketsAvailableForSale
            );

            if (donationProduct.currentQuantity >= min) {
                donationProduct.currentQuantity = min;
            }
            if (donationProduct.currentQuantity < 1) {
                donationProduct.currentQuantity = 1;
            }
        },
        changeCurrentValue(cartItem, event) {
            let value = event.target.value;

            if (
                value != 0 &&
                value != "" &&
                value >= cartItem.donationProduct.fixedValue
            ) {
                cartItem.donationProduct.currentQuantity = Math.floor(
                    value / cartItem.donationProduct.fixedValue
                );
                event.target.value =
                    cartItem.donationProduct.currentQuantity *
                    cartItem.donationProduct.fixedValue;
                cartItem.donationProduct.event = event;
            } else cartItem.donationProduct.currentQuantity = 1;

            this.changeCurrentQuantity(cartItem);
        },
        calculateMinValueForPurchase(
            maxItemQuantityPerCPF,
            ticketsAvailableForSale
        ) {
            let min = 0;
            if (maxItemQuantityPerCPF) {
                min =
                    ticketsAvailableForSale < maxItemQuantityPerCPF
                        ? ticketsAvailableForSale
                        : maxItemQuantityPerCPF;
            } else {
                min = ticketsAvailableForSale;
            }
            return min;
        },
        gaLightBoxDadosTitular() {
            if (ga)
                return ga("send", "pageview", "/carrinho/cartao-credito/dados-titular");
        },
        gaLightBoxCEP() {
            return ga(
                "send",
                "pageview",
                "/carrinho/cartao-credito/endereco-titular"
            );
        },
        getCreditCards() {
            return this.cartService.getCreditCards(this.cart.userId).then((data) => {
                if (data) {
                    this.creditCards = data;
                    let creditCard = this.creditCards.find((x) => x.isInUse);

                    if (creditCard) {
                        this.selectedCardId = creditCard.id;
                        this.cart.checkoutRequest.creditCardInfo.cardNumber = creditCard.lastDigits;
                    }

                    if (this.creditCards.length == 0)
                        this.addNewCard = true;
                }
            });
        },
        selectCreditCard(cardId, card) {
            if (this.selectedCardId == cardId) this.selectedCardId = 0;
            else this.selectedCardId = cardId;

            if (card) {
                this.cart.checkoutRequest.creditCardInfo.cardNumber = card.lastDigits;
            }
        },
        deleteCreditCard(creditCardId) {
            this.cartService.deleteCreditCard(creditCardId).then(() => {
                this.getCreditCards();
                this.CommonHelper.swal("Cartão excluido", null, "success");
            });
        },
        verifyURL() {
            if (this.$route.fullPath.includes("product=")) {
                this.projectService
                    .findDonationProductByCustomUrl(this.$route.query.product)
                    .then((data) => {
                        if (data) {
                            this.addCartItem(data.project, data.donationProduct);
                        }
                    }, error => {
                        if (error && error.errorMessage) {
                            this.onCartLoaded(this.cartLocal);
                        }
                    });
            } else {
                this.onCartLoaded(this.cartLocal);
            };

            //foi feito esse returno pois esse metodo eh chamado como uma promise em outros lugares
            return new Promise((resolve, reject) => { resolve(); });
        },
        CalculeFirstParcelamentToEvitTithe(quantity, value, period){
            var parcelament = parseFloat((value / quantity).toFixed(2));
            if((quantity * parcelament) != value){
                parcelament += value - (quantity * parcelament);   
            }             
            return parcelament            
        },
    },
    watch: {
        "cart.paymentMethodId": {
            handler: function (after, before) {
                if (after == PaymentMethod.Ticket || after == PaymentMethod.Pix || after == PaymentMethod.CreditCard) {
                    this.cart.donationRecurrencePeriodId = 1;
                }
                this.terms = false;
            },
            deep: true,
        },
        cartTotalPrice: function () {
            this.cart.taxValue = this.cartTaxMethodPayment();
        },
        coinSelected: function () {
            this.cart.checkoutRequest.coin = this.coinSelected;
        },
        selectDollar(newValue) {
            if (this.campaignLandingPageConfig.campaign.allowUsdDonation) {
                this.changeSelectedCoin(newValue);
            }
        },
        logged: {
            immediate: true,
            handler(newValue, oldValue) {
                if (newValue && !oldValue) {
                    this.companyService
                        .getBySignedInUser()
                        .then(this.loadPaymentMethodsWithCompany)
                        .then(this.reloadPaymentMethods);
                    this.locationService.listAllState(this.listAllStateCallback);
                }
            },
        },
        cartItems: function (newValue, oldValue) {
            newValue = this.updateItemsValue(newValue, oldValue);
            this.cart.cartItems = newValue;
            if (this.cart.cartItems < 1 && !this.dialogSuccess)
                this.$router.push({ name: Routes.app.CampaignLandingPage });
        },
        showLogin: function (newValue, oldValue) {
            if (!newValue && !this.logged) {
                this.cart.paymentMethodId = "";
            }
        },
        cartLocal: function (newValue, oldValue) {
            this.cart = newValue;
            this.cart.checkoutRequest.coin = this.coinSelected;
        },
    },
    computed: {
        ...mapGetters([
            "cartLocal",
            "cartItems",
            "logged",
            "showLogin",
            "userProfile",
        ]),
        simulatorPercentage() {
            return (this.campaignLandingPageConfig.campaign ? this.campaignLandingPageConfig.campaign.simulatorPercentage : 0)+'%';
        },
        getParcelamentTotalValue(){
            var parcelament = 1;
            if (this.cart.creditCardParcelament && this.cart.creditCardParcelament > 1 && this.cart.paymentMethodId != PaymentMethod.CreditCard) {
                parcelament = this.cart.creditCardParcelament;
            }            
            var parcelamentPrice = parseFloat((this.cartTotalPrice / parcelament).toFixed(2))
            var taxTotalPrice = this.cartTaxMethodPayment() * parcelament;
            var totalParcelamentValue = Math.ceil(parcelamentPrice * parcelament) + taxTotalPrice;
            return this.formatterHelper.formatMoney(totalParcelamentValue) ;
        },
        GethowMany(){
            var parcelamentList = [];
            var parcelamentValue = 0;
            var parcelamentValueNotFormated = 0
            var tax = 0;
            var parcelament = this.parcelamentMethod != null ? this.parcelamentMethod.parcelament : this.campaignLandingPageConfig.campaign.creditCardParcelament;
            this.minimumParcelamentValue = this.parcelamentMethod != null ? this.parcelamentMethod.minimumParcelamentValue : this.campaignLandingPageConfig.campaign.minimumParcelamentValue

            for (let index = 0; index < parcelament; index++) {
                var parcelamentObject = {...this.howMany[index]};
                var taxTotal = 0
                if (this.receiverParcelament.length > 0 && this.cartTotalPrice && index > 0) {
                    if (this.taxOwner == this.taxOwners.Company) {
                        parcelamentValue =  parcelamentValueNotFormated = this.cartTotalPrice / parcelamentObject.quantidade;
                        //parcelamentValue = this.CalculeFirstParcelamentToEvitTithe(parcelamentObject.quantidade, this.cartTotalPrice, index);
                    }
                    else
                    {                  
                        if (this.cart.paymentMethodId == this.PaymentMethod.CreditCard) {
                            tax = this.cartTotalPrice / ((100 - this.receiverParcelament.find( x => x.parcelament == parcelamentObject.quantidade).singleTransactionValue) / 100) - this.cartTotalPrice;
                            parcelamentValue =  parcelamentValueNotFormated = (this.cartTotalPrice + tax) / parcelamentObject.quantidade;
                            taxTotal = tax;
                            //parcelamentValue =  parcelamentValueNotFormated = this.CalculeFirstParcelamentToEvitTithe(parcelamentObject.quantidade, (this.cartTotalPrice + tax), index)
                        }
                        if (this.cart.paymentMethodId == this.PaymentMethod.Ticket) {
                            tax = this.configManager.taxBoleto;
                            parcelamentValue = parcelamentValueNotFormated = (this.cartTotalPrice / parcelamentObject.quantidade) + tax;
                            taxTotal = tax * parcelamentObject.quantidade
                            //parcelamentValue = parcelamentValueNotFormated = this.CalculeFirstParcelamentToEvitTithe(parcelamentObject.quantidade, this.cartTotalPrice, index) + tax;
                        }
                        if (this.cart.paymentMethodId == this.PaymentMethod.Pix) {
                            tax = this.getPixTaxParcelamentValue(parcelamentObject.quantidade);
                            parcelamentValue =  parcelamentValueNotFormated = (this.cartTotalPrice / parcelamentObject.quantidade) + tax;
                            taxTotal = tax * parcelamentObject.quantidade;
                            //parcelamentValue =  parcelamentValueNotFormated = this.CalculeFirstParcelamentToEvitTithe(parcelamentObject.quantidade, (this.cartTotalPrice + tax), index)
                        }
                    }

                    parcelamentValue = parseFloat(parcelamentValue.toFixed(2));

                    var totalWithoutTax = Math.ceil((parcelamentValue * parcelamentObject.quantidade) - taxTotal);

                    if (totalWithoutTax > this.cartTotalPrice 
                    && (this.cart.paymentMethodId == this.PaymentMethod.CreditCard
                    || this.cart.paymentMethodId == this.PaymentMethod.Pix)) {
                        totalWithoutTax = this.cartTotalPrice;
                    }

                    var totalParcelamentValue = totalWithoutTax + taxTotal;
                    totalParcelamentValue = this.formatterHelper.formatMoney(totalParcelamentValue);

                    if ((parcelamentValueNotFormated *  parcelamentObject.quantidade) < this.campaignLandingPageConfig.campaign.minimumDonationValue
                    || this.cartTotalPrice < this.campaignLandingPageConfig.campaign.minimumDonationValue) {
                        break;
                    }
                    if (parcelamentValueNotFormated < this.minimumParcelamentValue) {
                        break;
                    }

                    parcelamentObject.name = this.howMany[index].name  + ' ' + this.formatterHelper.formatMoney(parcelamentValue).toString() + ' __________ ' + 'Valor total: ' +  this.formatterHelper.formatMoney(this.cartTotalPrice + tax);
                }
                    
                parcelamentList.push(parcelamentObject);
            }

            return parcelamentList;
        },
        getCoinSelectedObj() {
            for (let i = 0; i < this.coins.length; i++) {
                const el = this.coins[i];
                if (el.id == this.coinSelected) return el;
            }
        },
        itemOrProject() {
            return "projeto";
        },
        itemsOrProjects() {
            return "projetos";
        },
        donationOrPurchase() {
            return "doação";
        },
        showTicketAlert() {
            return false;
        },
        finishedCartId() {
            if (this.finishedCart) return this.finishedCart.id;
            return 0;
        },
        finishedCartTotalValue() {
            if (this.finishedCart) return this.finishedCart.totalValue;
            return 0;
        },
        finishedPaymentMethodId() {
            if (this.finishedCart) return this.finishedCart.paymentMethodId;
            return 0;
        },
        getParcelament() {
            if (
                this.cart &&
                this.cart.paymentMethodId == PaymentMethod.PayrollDeduction
            ) {
                let parc = this.parcelamentsDivided.filter(
                    (p) => p.id == this.cart.parcelamentId
                );
                if (parc && parc.length > 0) return parc[0];
            }
            return null;
        },
        parcelamentsDivided() {
            if (this.parcelaments == null) return [];
            let options = JSON.parse(JSON.stringify(this.parcelaments));
            options.forEach((el) => {
                let value =
                    this.cartTotalPrice != 0 && el.howMany != 0
                        ? this.cartTotalPrice / el.howMany
                        : this.cartTotalPrice;
                if (el.howMany != 0)
                    el.description =
                        el.howMany +
                        "x R$ " +
                        this.formatterHelper.formatMoney(value, 2) +
                        " " +
                        el.description;
                else
                    el.description =
                        el.description +
                        " 1x R$ " +
                        this.formatterHelper.formatMoney(this.cartTotalPrice, 2);
            });
            if (options.length == 1) this.cart.parcelamentId = options[0].id;
            return options;
        },
        campaignDonationRecurrencePeriodList() {
            if (this.campaignLandingPageConfig.campaign == null) return [];
            var list = this.campaignLandingPageConfig.campaign.campaignDonationRecurrencePeriodList.map(
                function (campaignDonationRecurrencePeriod) {
                    return {
                        id: campaignDonationRecurrencePeriod.donationRecurrencePeriodId,
                        name: campaignDonationRecurrencePeriod.donationRecurrencePeriod.name,
                    };
                }
            );

            if (list.length == 1) this.cart.donationRecurrencePeriodId = list[0].id;
            return list;
        },
        cartTotalPrice() {
            if (list.length == 1) this.cart.donationRecurrencePeriodId = list[0].id;
            return list;
        },
        cartTotalPrice() {
            if (!this.cart || !this.cart.cartItems) {
                return 0;
            }

            let price = this.cart.cartItems.reduce((accumulator, cartItem) => {
                return (
                    accumulator +
                    (cartItem.donationProduct && cartItem.donationProduct.currentQuantity
                        ? cartItem.donationProduct.currentQuantity *
                        cartItem.donationProduct.fixedValue
                        : cartItem.itemValue == null
                            ? 0
                            : cartItem.itemValue)
                );
            }, 0);
            if (this.coinSelected == "USD") price = price * this.dolarPrice;

            return price;
        },
        recurrenceText() {
            let recurrencePeriod = parseInt(this.cart.donationRecurrencePeriodId);

            if (recurrencePeriod === DonationRecurrencePeriod.EveryOneMonth) {
                return "por mês";
            }
            if (recurrencePeriod === DonationRecurrencePeriod.EveryTwoMonths) {
                return "a cada 2 meses";
            }
            if (recurrencePeriod === DonationRecurrencePeriod.EveryThreeMonths) {
                return "a cada 3 meses";
            }
            if (recurrencePeriod === DonationRecurrencePeriod.EveryFourMonths) {
                return "a cada 4 meses";
            }
            if (recurrencePeriod === DonationRecurrencePeriod.EverySixMonths) {
                return "a cada 6 meses";
            }
            if (recurrencePeriod === DonationRecurrencePeriod.EveryTwelveMonths) {
                return "por ano";
            }

            return "";
        },
        minimumPeriodDonationValue() {
            let minimumDonationValue = 0;

            if (
                this.cart.paymentMethodId == this.paymentMethods.CreditCard &&
                this.cart.donationRecurrencePeriodId
            ) {
                let recurrencePeriod = parseInt(this.cart.donationRecurrencePeriodId);

                let campaignDonationRecurrencePeriodList = this.campaignLandingPageConfig.campaign.campaignDonationRecurrencePeriodList.find(
                    (cdrp) => cdrp.donationRecurrencePeriodId == recurrencePeriod
                );

                minimumDonationValue =
                    campaignDonationRecurrencePeriodList.minimumDonationValue > 0
                        ? campaignDonationRecurrencePeriodList.minimumDonationValue
                        : this.campaignLandingPageConfig.campaign.minimumDonationValue;

                if (!this.cart.campaign){                    
                    this.cart.campaign = this.campaignLandingPageConfig.campaign;
                    }
                this.cart.campaign.minimumDonationValue = minimumDonationValue;
            } else {
                minimumDonationValue = this.campaignLandingPageConfig.campaign.minimumDonationValue;

                if (!this.cart.campaign){                    
                    this.cart.campaign = this.campaignLandingPageConfig.campaign;
                }
                    
                this.cart.campaign.minimumDonationValue = this.campaignLandingPageConfig.campaign.minimumDonationValue;
            }

            return minimumDonationValue;
        },
        isPaymentSimplified() {
            return (
                this.cart.donationRecurrencePeriodId ==
                DonationRecurrencePeriod.Single &&
                this.providerId == PaymentMethodProvider.PagarMe
            );
        },
        accountDebitShowDay() {
            return (
                this.cart.donationRecurrencePeriodId !=
                DonationRecurrencePeriod.Single &&
                this.cart.donationRecurrencePeriodId != null
            );
        },
        companyCampaignName() {
            if (
                this.campaignLandingPageConfig &&
                this.campaignLandingPageConfig.campaign &&
                this.campaignLandingPageConfig.campaign.company &&
                this.campaignLandingPageConfig.campaign.company.entity
            ) {
                return this.campaignLandingPageConfig.campaign.company.entity.name;
            }
            return "";
        },
        companyCampaignCNPJ() {
            if (
                this.campaignLandingPageConfig &&
                this.campaignLandingPageConfig.campaign &&
                this.campaignLandingPageConfig.campaign.company &&
                this.campaignLandingPageConfig.campaign.company.entity
            ) {
                return this.campaignLandingPageConfig.campaign.company.entity.cnpj;
            }
            return "";
        },
    },
    filters: {
        cnpj: function (cnpj) {
            return new FormatterHelper().formatCnpj(cnpj);
        },
    },
};
</script>
<style scoped>
.close-project-cart {
    position: absolute;
    right: 0;
}
</style>
